WiseMetering.Views.AddOpportunity = WiseMetering.Views.Modal.Save.extend({
    template: 'templates/opportunities/add',
    className: 'mbx-info text-left',
    form_prefix: 'opportunity',
    title: () => i18next.t('modal.title.opportunities.new'),
    yourVar: 1,

    ui: {
        capex: 'input#opportunity-capex',
        consumption: 'input#opportunity-consumption',
        cost: 'input#opportunity-cost',
        description: 'input#opportunity-description',
        priority: 'input#opportunity-priority',
        spinner: '#spinner',
        title: 'input#opportunity-title',
        uploadFile: '#upload-file',
        utility_kind: 'select#opportunity-utility-kind',
        indicators: '#opportunity-indicator',
        indicatorContainer: '#opportunity-indicator-container'
    },

    events: {
        'change #opportunity-indicator': 'onChangeIndicator',
        'change select#opportunity-utility-kind': 'onChangeUtilityKind'
    },

    afterSave: function() {
        if (this.model.file) {
            let formData = new FormData();
            formData.append('file', this.model.file);
            this.collection.get(this.model.id).uploadFile(formData).fail((response) => {
                let message = JSON.parse(response.responseText)?.errors?.file || i18next.t('opportunities.failed_to_upload_file');
                WiseMetering.layout.showTipper('error', message, 5000);
            });
        }
    },

    getFormData: function() {
        let data = this.formSerializer();
        if (WiseMetering.utilityKinds.get(data.utility_kind_id).get('unit') === 'kWh') {
            data.consumption = data.consumption * 1000;
        }
        this.model.file = this.ui.uploadFile.prop('files')[0];
        return data;
    },

    onChangeIndicator: function() {
        this.selectedIndicator = this.options.indicators.get(this.$('.indicators option:selected').val());
    },

    onChangeUtilityKind: function() {
        this.utilityKindId = this.ui.utility_kind.val();
        const unit = WiseMetering.utilityKinds.get(this.utilityKindId).get('unit');
        this.ui.consumption.attr('placeholder', i18next.t('opportunities.please_insert_consumption', { unit }));
        this.updateIndicatorsOptions();
    },

    onRender: function() {
        this.ui.capex.attr('placeholder', i18next.t('opportunities.please_insert_capex', { currency: WiseMetering.getCurrency() }));
        this.ui.cost.attr('placeholder', i18next.t('opportunities.please_insert_savings', { currency: WiseMetering.getCurrency() }));
    },

    serializeData: function () {
        let json = this.model.toJSON();
        json.availableUtilityKinds = this.model.building().availableUtilityKinds();
        json.form_prefix = this.form_prefix;
        json.indicators = this.options.indicators;
        json.selectedIndicator = this.selectedIndicator || {};
        return json;
    },

    updateIndicators: function () {
        const utilityKind = WiseMetering.utilityKinds.get(this.utilityKindId);

        let indicators = WiseMetering.indicators.where({
            building_id: this.model.get('building_id'),
            kind_slug: utilityKind.indicatorKindSlug()
        })
        this.options.indicators.reset();
        this.options.indicators.add(indicators);
    },

    updateIndicatorsOptions: function() {
        this.updateIndicators();
        this.ui.indicatorContainer.show();
        this.ui.indicators.find('option').remove().end();
        this.ui.indicators.append(`<option value='' selected>${i18next.t('indicators.no_indicators_found')}</option>`);
        this.options.indicators.forEach(function(indicator) {
            this.ui.indicators.append(`<option value="${indicator.id}">${indicator.get('name')}</option>`);
        }.bind(this))
    }
});
