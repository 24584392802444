WiseMetering.Views.NewReport = WiseMetering.Views.Modal.Save.extend({
    className: 'mbx-info text-left',

    events: {
        'change #report-type': 'onTypeChange',
        'change #report-comparison-section': 'showPeriod',
        'change #report-details-section': 'showPeriod',
        'change #report-file-section': 'showPeriod',
        'change #report-folder-section': 'showPeriod',
        'change #report-survey-section': 'showPeriod',
        'change #report-period': 'onPeriodChange',
        'change #report-year': 'onPeriodChange',
        'change #report-quarter': 'onPeriodChange',
        'change #report-month': 'onPeriodChange',
        'change #report-month-end': 'reportExceptions',
        'change #report-year-end': 'reportExceptions'
    },

    form_prefix: 'report',

    frequencies: [
        { title: 'Month', value: 'month' },
        { title: 'Quarter', value: 'quarter' },
        { title: 'Year', value: 'year' }
    ],

    input_readers: [
        { func: $element => $element.val() === '' ? null : $element.val(), type: 'hidden' },
        { func: $element => $element.val() === '' ? null : $element.val(), type: 'select' }
    ],

    template: 'templates/reports/new',

    title: null,

    width: 500,

    eventsReportSettings: function(type) {
        if (['ZoneEventsReport', 'GlobalEventsReport'].includes(type)) {
            this.showPeriod();
        }
    },

    folderReportSettings: function(type) {
        const folderElement = this.$('#report-folder-section');
        ['GlobalInvoiceReport', 'ZoneInvoiceReport', 'ZoneFolderReport'].includes(type) ? folderElement.show() : folderElement.hide();
    },

    getFormData: function() {
        let data = this.formSerializer();
        if (data.folder_id) {
            this.model.unset('building_id');
        } else if (this.options.buildingId) {
            delete data.folder_id;
            data.building_id = this.options.buildingId;
        } else {
            delete data.folder_id;
            data.organization_id = WiseMetering.currentOrganization.id;
        }
        return data;
    },

    getReportType(type) {
        return WiseMetering.zoneReportTypes.findWhere({ type: type }) || WiseMetering.globalReportTypes.findWhere({ type: type });
    },

    globalDetailedReportSettings: function(type) {
        if (type === 'GlobalDetailedReport') {
            this.showPeriod();
        }
    },

    globalTagusPark: function(type) {
        if (type === 'GlobalTagusParkReport') {
            this.showPeriod();
        }
    },

    globalVodafoneSettings: function(type) {
        const fileElement = this.$('#report-file-section');
        type === 'GlobalVodafoneReport' ? fileElement.show() : fileElement.hide();
    },

    initializeForm: function() {
        this.$('#report-period-section').hide();
        this.$('#report-year-section').hide();
        this.$('#report-month-section').hide();
        this.$('#report-quarter-section').hide();
        this.$('#report-end-section').hide();

        this.$('#report-period').val('');
        this.$('#report-utility-kind').val('');
        this.$('#report-comparison').val('');
        this.$('#report-file').val('');
        this.$('#report-folder').val('');
        this.$('#report-details').val('');
        this.$('#report-survey').val('');
    },

    iosaException: function() {
        const isIosa = WiseMetering.currentOrganization.get('subdomain') === 'iosa',
            isMonthly = this.$('#report-period').val() === 'month',
            isInvoiceReport = this.$('#report-type').val() === 'ZoneInvoiceReport';

        if (isIosa && isInvoiceReport && isMonthly) {
            this.$('#report-end-section').show();
            const selectedMonth = this.$('#report-month-end').val(),
                selectedYear = parseInt(this.$('#report-year-end').val()),
                to = moment(`${selectedYear}-${selectedMonth > 9 ? selectedMonth : `0${selectedMonth}`}-01`).endOf('month');

            this.$('#report-to').val(to.format('YYYY-MM-DD HH:mm:ss'));
        } else {
            this.$('#report-end-section').hide();
        }
    },

    markersReportSettings: function(type) {
        if (type === 'ZoneMarkersReport') {
            this.showPeriod();
        }
    },

    onPeriodChange: function() {
        this.showYear();

        const selectedPeriod = this.$('#report-period').val(),
            selectedMonth = this.$('#report-month').val(),
            selectedQuarter = this.$('#report-quarter').val(),
            selectedYear = parseInt(this.$('#report-year').val()),
            now = moment();

        if (selectedYear === now.year()) {
            const month = now.month() + 1;
            this.$('#report-month option').each(function(_index, element) {
                element = $(element);
                if (element.val() > month) {
                    element.hide();
                }
            });
            if (selectedMonth > month) {
                this.$(`#report-month option[value=${month}]`).prop('selected', true);
            }

            const quarter = now.quarter();
            this.$('#report-quarter option').each(function(_index, element) {
                element = $(element);
                if (element.val() > quarter) {
                    element.hide();
                }
            });
            if (selectedQuarter > quarter) {
                this.$(`#report-quarter option[value=${quarter}]`).prop('selected', true);
            }
        } else {
            this.$('#report-month option').show();
            this.$('#report-quarter option').show();
        }

        this.$('#report-month-section')[selectedPeriod === 'month' ? 'show' : 'hide']();
        this.$('#report-quarter-section')[selectedPeriod === 'quarter' ? 'show' : 'hide']();

        let month = 1;
        if (selectedPeriod === 'month') {
            month = parseInt(this.$('#report-month').val());
        } else if (selectedPeriod === 'quarter') {
            month = (parseInt(this.$('#report-quarter').val()) - 1) * 3 + 1;
        }

        const from = `${selectedYear}-${month > 9 ? month : `0${month}`}-01 00:00:00`,
            to = moment(from).endOf(selectedPeriod).format('YYYY-MM-DD HH:mm:ss');

        this.$('#report-from').val(from);
        this.$('#report-to').val(to);

        this.reportExceptions();
    },

    onShow: function() {
        this.$('#report-description-section').hide();
        this.initializeForm();

        let language = WiseMetering.currentOrganization.get('language').toLowerCase();
        this.$(`#report-language > option[value='${language}']`).prop('selected', true);
    },

    onTypeChange: function() {
        const type = this.$('#report-type').val();
        this.initializeForm();
        this.setReportDescription(type);
        this.utilityKindReportSettings(type);
        this.folderReportSettings(type);
        this.markersReportSettings(type);
        this.schemasReportSettings(type);
        this.surveyReportSettings(type);
        this.eventsReportSettings(type);
        this.zoneDetailedReportSettings(type);
        this.optimiserReportSettings(type);
        this.globalDetailedReportSettings(type);

        // Custom Reports
        this.globalVodafoneSettings(type);
        this.globalTagusPark(type);
        this.reportExceptions();
    },

    optimiserReportSettings: function(type) {
        if (type === 'GlobalOptimiserReport') {
            this.$('#report-period-section').hide();
            this.$('#report-year-section').hide();
            this.$('#report-month-section').hide();

            let from = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
                to = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');

            this.$('#report-from').val(from);
            this.$('#report-to').val(to);
        }
    },

    reportExceptions: function() {
        this.iosaException();
    },

    schemasReportSettings: function(type) {
        if (type === 'ZoneSchemaReport' || type === 'ZoneMeteringSchemaReport') {
            this.$('#report-period-section').hide();
            this.$('#report-year-section').hide();
            this.$('#report-month-section').hide();

            let from = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
                to = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss');

            this.$('#report-from').val(from);
            this.$('#report-to').val(to);
        }
    },

    serializeData: function() {
        const now = moment();

        // the || null is required, because looking for undefined building_id will return 0 results
        const folders = WiseMetering.folders.where({ building_id: this.options.buildingId || null }).map(folder => {
            const id = folder.id;
            let
                depth = 0,
                name = folder.get('name'),
                parent = folder.parent();

            while (parent && parent.type() !== 'building') {
                name = `${parent.get('name')} > ${name}`;
                parent = parent.parent();
                depth += 1;
            }
            return { depth, id, name };
        }).sort((a, b) => {
            // make the ones with depth 0 appear first
            if (a.depth === 0 || b.depth === 0) {
                if (a.depth === b.depth) {
                    return a.name > b.name ? 1 : -1;
                }
                return a.depth > b.depth ? 1 : -1;
            } else {
                return a.name > b.name ? 1 : -1;
            }
        });

        let reports = this.options.buildingId ? WiseMetering.zoneReportTypes.filterTypes(this.options.buildingId) : WiseMetering.globalReportTypes.filterTypes();

        return Object.assign({}, this.model.toJSON(), {
            building_id: this.options.buildingId,
            folders,
            reports: reports,
            utilityKinds: WiseMetering.utilityKinds.map(x => ({ slug: x.get('slug'), name: x.get('name') })),
            frequencies: this.frequencies,
            month: now.month() + 1,
            quarter: now.quarter(),
            quarters: [1, 2, 3, 4],
            year: now.year(),
            years: this.model.reportingYears()
        });
    },

    setReportDescription: function(type) {
        let reportType = this.getReportType(type);
        this.$('#report-description-section').show();
        this.$('#report-description-section').html(reportType.getDescription(type));
    },

    showPeriod: function() {
        this.$('#report-period-section').show();
    },

    showYear: function() {
        this.$('#report-year-section').show();
    },

    surveyReportSettings: function(type) {
        const surveyElement = this.$('#report-survey-section');
        type === 'ZoneSurveyReport' ? surveyElement.show() : surveyElement.hide();
    },

    utilityKindReportSettings: function(type) {
        const
            option = this.$('#report-type option:selected').text(),
            comparisonElement = this.$('#report-comparison-section'),
            utilityKindElement = this.$('#report-utility-kind-section'),
            isSingleUtilityKind = option === 'UtilityKind';

        ['ZoneUtilityReport', 'GlobalUtilityReport'].includes(type) ? comparisonElement.show() : comparisonElement.hide();

        isSingleUtilityKind ? utilityKindElement.show() : utilityKindElement.hide();
    },

    zoneDetailedReportSettings: function(type) {
        const detailsElement = this.$('#report-details-section');
        type === 'ZoneDetailedReport' ? detailsElement.show() : detailsElement.hide();
    }
});
