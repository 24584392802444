module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        ';
 if (type !== 'retail') { ;
__p += '\n            <dt>\n                <label for=\'organization_automation\'>' +
((__t = ( i18next.t('glossary.automation') )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dt>\n                <input ' +
((__t = ( automation ? 'checked' : '' )) == null ? '' : __t) +
' class=\'vertical-middle\' id=\'organization_automation\' name=\'organization[automation]\' type=\'checkbox\'>\n            </dt>\n        ';
 } ;
__p += '\n    </dl>\n</form>\n';

}
return __p
}