module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class=\'basic-widget table-widget grid-half\'>\n    <div class=\'title\'>\n      ' +
((__t = ( title )) == null ? '' : __t) +
'\n      <span class=\'basic-widget-options\' id=\'widget-options-table\'>' +
((__t = ( WiseMetering.icon('gear') )) == null ? '' : __t) +
'</span>\n    </div>\n    <div class=\'empty-content\'>\n        <div class=\'empty-symbol\'>\n            ' +
((__t = ( WiseMetering.icon('circle_exclamation') )) == null ? '' : __t) +
'\n        </div>\n        ' +
((__t = ( i18next.t('ui.no_data_available').capitalize() )) == null ? '' : __t) +
'\n        <div class="empty-message">\n            <p>' +
((__t = ( message )) == null ? '' : __t) +
'</p>\n        </div>\n    </div>\n</div>\n\n';

}
return __p
}