import loadLocales from '@locales';

$.ajaxSetup({
    beforeSend: (_xhr, options) => {
        if (!options.url.startsWith('http')) {
            options.url = WiseMetering.apiUrl + options.url;
        }
    },
    statusCode: {
        401: () => {
            if (window.location.pathname !== '/login') {
                WiseMetering.Views.Layouts.Root.prototype.logout();
            }
        }
    },
    xhrFields: {
        withCredentials: true
    }
});

// Initialize Marionette application
window.JST = {};

Backbone.Marionette.Renderer.render = function(template, data) {
    return JST[template] ? JST[template](data) : _.template($(template).html(), data);
};

const WiseMetering = new Backbone.Marionette.Application();
window.WiseMetering = WiseMetering;

WiseMetering.loadLang = function(lang) {
    loadLocales(lang);
    moment.locale(lang);
    Highcharts.setOptions({ lang: i18next.t('highcharts', { returnObjects: true }) });
    WiseMetering.AvailableWidgets = WiseMetering.AvailableWidgetsGenerator();
};

// Tell the application where to live - in this case we'll put everything in the #layout div

WiseMetering.addRegions({
    mainRegion: '#layout',
    modalBox: '#modal'
});

WiseMetering.apiUrl = process.env.API_URL || '';
WiseMetering.configUrl = process.env.CONFIG_URL || '';
WiseMetering.AvailableWidgetsGenerator = () => ({
    'benchmark': i18next.t('widgets.title.benchmark'),
    'co2': i18next.t('widgets.title.co2'),
    'requested_power': i18next.t('widgets.title.requested_power'),
    'electricity_circuits': i18next.t('widgets.title.electricity_circuits'),
    'electricity_cost_period': i18next.t('widgets.title.electricity_cost_period'),
    'electricity_cost_type': i18next.t('widgets.title.electricity_cost_type'),
    'electricity_objective_consumption': i18next.t('widgets.title.electricity_objective_consumption'),
    'electricity_objective_cost': i18next.t('widgets.title.electricity_objective_cost'),
    'electricity_trend': i18next.t('widgets.title.electricity_trend'),
    'electricity_zones': i18next.t('widgets.title.electricity_zones'),
    'electricity_opportunity': i18next.t('widgets.title.electricity_opportunity'),

    'water_cost_period': i18next.t('widgets.title.water_cost_period'),
    'water_cost_type': i18next.t('widgets.title.water_cost_type'),
    'water_objective_consumption': i18next.t('widgets.title.water_objective_consumption'),
    'water_objective_cost': i18next.t('widgets.title.water_objective_cost'),
    'water_trend': i18next.t('widgets.title.water_trend'),
    'water_opportunity': i18next.t('widgets.title.water_opportunity'),

    'oil_cost_period': i18next.t('widgets.title.oil_cost_period'),
    'oil_cost_type': i18next.t('widgets.title.oil_cost_type'),
    'oil_objective_consumption': i18next.t('widgets.title.oil_objective_consumption'),
    'oil_objective_cost': i18next.t('widgets.title.oil_objective_cost'),
    'oil_trend': i18next.t('widgets.title.oil_trend'),
    'oil_opportunity': i18next.t('widgets.title.oil_opportunity'),

    'gas_energy_cost_period': i18next.t('widgets.title.gas_energy_cost_period'),
    'gas_energy_cost_type': i18next.t('widgets.title.gas_energy_cost_type'),
    'gas_energy_objective_consumption': i18next.t('widgets.title.gas_energy_objective_consumption'),
    'gas_energy_objective_cost': i18next.t('widgets.title.gas_energy_objective_cost'),
    'gas_energy_trend': i18next.t('widgets.title.gas_energy_trend'),
    'gas_energy_opportunity': i18next.t('widgets.title.gas_energy_opportunity'),

    'gas_volume_cost_period': i18next.t('widgets.title.gas_volume_cost_period'),
    'gas_volume_cost_type': i18next.t('widgets.title.gas_volume_cost_type'),
    'gas_volume_objective_consumption': i18next.t('widgets.title.gas_volume_objective_consumption'),
    'gas_volume_objective_cost': i18next.t('widgets.title.gas_volume_objective_cost'),
    'gas_volume_trend': i18next.t('widgets.title.gas_volume_trend'),
    'gas_volume_opportunity': i18next.t('widgets.title.gas_volume_opportunity'),

    'cooled_water_energy_cost_period': i18next.t('widgets.title.cooled_water_energy_cost_period'),
    'cooled_water_energy_cost_type': i18next.t('widgets.title.cooled_water_energy_cost_type'),
    'cooled_water_energy_objective_consumption': i18next.t('widgets.title.cooled_water_energy_objective_consumption'),
    'cooled_water_energy_objective_cost': i18next.t('widgets.title.cooled_water_energy_objective_cost'),
    'cooled_water_energy_trend': i18next.t('widgets.title.cooled_water_energy_trend'),
    'cooled_water_energy_opportunity': i18next.t('widgets.title.cooled_water_energy_opportunity'),

    'hot_water_energy_cost_period': i18next.t('widgets.title.hot_water_energy_cost_period'),
    'hot_water_energy_cost_type': i18next.t('widgets.title.hot_water_energy_cost_type'),
    'hot_water_energy_objective_consumption': i18next.t('widgets.title.hot_water_energy_objective_consumption'),
    'hot_water_energy_objective_cost': i18next.t('widgets.title.hot_water_energy_objective_cost'),
    'hot_water_energy_trend': i18next.t('widgets.title.hot_water_energy_trend'),
    'hot_water_energy_opportunity': i18next.t('widgets.title.hot_water_energy_opportunity')
});

WiseMetering.Helpers = {};
WiseMetering.Views = {
    Costs: {},
    Crud: {},
    Layouts: {},
    Modal: {},
    Ui: {
        Widget: {}
    }
};

// ALL THE ABOVE CODED HAS BEEN MOVED FROM THE OLD WM TO GUARANTEE COMPATIBILITY
// VERY IMPORTANT TODO: RETHINK AND REFACTOR THIS PIECE OF BAD CODE

WiseMetering.baseColors = {
    black: '#000000',
    blue: '#6EB3E3',
    dark_blue: '#4D7D9E',
    light_blue: '#99C9EB',
    green: '#8BC53F',
    dark_green: '#224C4F',
    light_green: '#B4D362',
    magenta: '#C974AD',
    grey: '#2C2C2C',
    mid_grey: '#797979',
    light_grey: '#D8D8D8',
    orange: '#F59D24',
    yellow: '#F5D124',
    red: '#E94547',
    dark_red: '#A33031',
    white: '#FFFFFF'
};

// Colors
WiseMetering.WidgetColors = {};

WiseMetering.WidgetColors.Default = [
    WiseMetering.baseColors.green,
    WiseMetering.baseColors.dark_green,
    WiseMetering.baseColors.grey,
    WiseMetering.baseColors.grey
];

WiseMetering.WidgetColors.MultiColor = [
    WiseMetering.baseColors.blue,
    WiseMetering.baseColors.red,
    WiseMetering.baseColors.orange,
    WiseMetering.baseColors.green,
    WiseMetering.baseColors.grey,
    WiseMetering.baseColors.yellow,
    WiseMetering.baseColors.magenta
];

WiseMetering.WidgetColors.Benchmarking = [
    WiseMetering.baseColors.grey,
    WiseMetering.baseColors.green
];

WiseMetering.WidgetColors.Green = ['#3B4D0C', '#688715', WiseMetering.baseColors.green, '#B4D362', '#D4E6A5'];

WiseMetering.WidgetColors.Red = ['#5D1B1C', '#A33031', WiseMetering.baseColors.red, '#EF7C7E', '#F6B4B5'];

WiseMetering.WidgetColors.Grey = ['#191919', '#2C2C2C', WiseMetering.baseColors.grey, '#797979', '#B2B2B2'];

WiseMetering.WidgetColors.utilityKindColors = {
    electricity: WiseMetering.baseColors.green,
    gas_energy: WiseMetering.baseColors.orange,
    gas_volume: WiseMetering.baseColors.orange,
    hot_water_energy: WiseMetering.baseColors.red,
    cooled_water_energy: WiseMetering.baseColors.dark_blue,
    water: WiseMetering.baseColors.blue,
    oil: WiseMetering.baseColors.black
};

WiseMetering.SemaphorColors = {
    green: '#2dc937',
    yellow: '#e7b416',
    orange: '#db7b2b',
    red: '#cc3232',
    blue: '#6EB3E3'
};

WiseMetering.Colors = {
    blue: '#6EB3E3',
    green: '#8BC53F',
    red: '#E94547',
    orange: '#F59D24',
    lightGrey: '#C5C5C5',
    AlarmTemplates: {
        0: 'rgb(154, 175, 195)',
        1: 'rgb(111, 135, 135)',
        2: 'rgb(174, 194, 174)',
        3: 'rgb(176, 181, 123)',
        4: 'rgb(136, 143, 81)',
        5: 'rgb(229, 223, 174)'
    },
    Schedules: {
        on: '#427097',
        off: '#9AAFC3',
        sun: '#777777'
    }
};

// Names - not very pretty stuff
WiseMetering.MonthsEN = {
    'Jan': 'January',
    'Feb': 'February',
    'Mar': 'March',
    'Apr': 'April',
    'May': 'May',
    'Jun': 'June',
    'Jul': 'July',
    'Aug': 'August',
    'Sep': 'September',
    'Oct': 'October',
    'Nov': 'November',
    'Dec': 'December'
};

WiseMetering.SI_PREFIXES_FACTORS = {
    'T': 1000000000000,
    'G': 1000000000,
    'M': 1000000,
    'k': 1000,
    'h': 100,
    'c': 0.01,
    'm': 0.001,
    'μ': 0.000001
};

WiseMetering.weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday', 'holiday'];
WiseMetering.PREFIXABLE_UNITS = ['W', 'Wh'];
WiseMetering.RATES = {
    900: '15 minutes',
    3600: '1 hour',
    86400: '1 day',
    2592000: '1 month',
    31536000: '1 year'
};

WiseMetering.Collection = Backbone.Collection.extend({
    comparator: model => {
        let builtIn = 1;
        if (model.get('built_in')) {
            builtIn = 0;
        }

        let name = model.get('name');
        if (name) {
            name = WiseMetering.utils.searchableText(name);
        }

        return `${builtIn}-${name}-${model.id}`;
    },

    fetch: function(options) {
        if (this.hasOrganization) {
            options = options || {};
            options.data = options.data || {};
            options.data.organization_id = WiseMetering.currentOrganization.id;
        }
        return Backbone.Collection.prototype.fetch.call(this, options);
    }
});

WiseMetering.Model = Backbone.Model.extend({
    creator: function() {
        return WiseMetering.users.get(this.get('created_by')) || new WiseMetering.Model({ name: 'System' });
    },

    lastChange: function() {
        return `${this.updater().get('name')} @ ${WiseMetering.utils.formatDate(this.get('updated_at'))}`;
    },

    name: function() {
        return this.get('name');
    },

    updater: function() {
        return WiseMetering.users.get(this.get('updated_by')) || new WiseMetering.Model({ name: 'System' });
    }
});

WiseMetering.getCurrency = function(scale) {
    const currency = WiseMetering.currentOrganization.get('currency');

    switch (scale) {
        case 0.01:
            return currency === '€' ? 'cent' : 'pence';
        case 1000:
            return 'k' + currency;
        default:
            return currency;
    }
};

WiseMetering.filter = {
    name: undefined,
    group: undefined
};

WiseMetering.currentUser = null;
WiseMetering.currentOrganization = null;

// ------------------------------------------------------------------------------
WiseMetering.locales = {
    // -----------------------------------------
    'PT': {
        currency: {
            template: _.template('<%= value %> €'),
            symbol: '€'
        },
        decimal_separator: ',',
        digit_group_separator: '.',
        name: 'pt-PT'
    },
    // -----------------------------------------
    'BR': {
        currency: {
            template: _.template('R$ <%= value %>'),
            symbol: 'R$'
        },
        decimal_separator: ',',
        digit_group_separator: '.',
        name: 'pt-BR'
    },
    // ----------------------------------------
    'AO': {
        currency: {
            template: _.template('Kz <%= value %>'),
            symbol: 'Kz'
        },
        decimal_separator: ',',
        digit_group_separator: '.',
        name: 'pt-AO'
    },
    // ----------------------------------------
    'GB': {
        currency: {
            template: _.template('£ <%= value %>'),
            symbol: '£'
        },
        decimal_separator: '.',
        digit_group_separator: ',',
        name: 'en-GB'
    }
};

WiseMetering.currentLocale = null;

WiseMetering.onCancel = ({ keyCode }) => {
    if (keyCode === 27) {
        if (WiseMetering.modalBox.currentView) {
            WiseMetering.modalBox.close();
        } else {
            WiseMetering.controller.drawDefault();
        }
    }
};

WiseMetering.setOrganization = () => {
    const
        subdomain = window.location.pathname.split('/')[1],
        organization = WiseMetering.currentUser.get('organizations').find(org => org.subdomain === subdomain);

    WiseMetering.currentOrganization = new WiseMetering.Model.Organization({
        id: (organization && organization.id) || WiseMetering.currentUser.get('organization_id')
    });
};
