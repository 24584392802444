import { Card, CardActions, CardContent, Typography } from '@mui/material';
import percentage from '@utils/percentage';
import React from 'react';

const Component = props => {
    const { active, total } = props.data;

    return (
        <Card>
            <CardContent sx={{ height: 260 }}>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Buildings
                </Typography>
                <Typography variant="h5" component="div">
                    Total: {total}
                </Typography>
                <br/>
                <Typography variant="body2">
                    Active: {active} ({percentage(active, total)})
                </Typography>
            </CardContent >
            <CardActions sx={{ height: 20 }}>
            </CardActions>
        </Card>
    );
};

export default Component;
