WiseMetering.Views.EditAllocation = WiseMetering.Views.Modal.Save.extend({
    className: 'mbx-info text-left',
    form_prefix: 'allocation',
    template: 'templates/allocations/edit',
    title: () => i18next.t('modal.title.allocations.edit'),

    ui: {
        to: '#allocation_to',
        from: '#allocation_from'
    },

    events: {
        'change #allocation_utility_kind': 'onChangeUtilityKind'
    },

    serializeData: function() {
        if (!this.selectedUtilityKind) {
            this.setSelectedUtilityKind();
        }
        let json = this.model.toJSON();
        json.from = this.model.getDate('from');
        json.to = this.model.getDate('to');
        json.utilityKinds = WiseMetering.utilityKinds;
        json.indicators = this.options.indicators;
        json.tariffs = this.options.tariffs;
        json.selectedUtilityKind = this.selectedUtilityKind;
        return json;
    },

    onRender: function() {
        const datepickerOptions = {
            dateFormat: 'yy-mm-dd',
            changeDay: true,
            changeMonth: true,
            changeYear: true
        };

        this.ui.from.datepicker(datepickerOptions);
        this.ui.to.datepicker(datepickerOptions);
    },

    onChangeUtilityKind: function(event) {
        event.preventDefault();
        this.model.set(this.formSerializer());
        this.selectedUtilityKind = this.$('.utility_kinds option:selected').val();
        this.updateIndicators();
        this.updateTariffs();
        this.render();
    },

    setSelectedUtilityKind: function() {
        this.selectedUtilityKind = this.selectedUtilityKind || this.model.indicator().utilityKindName();

        this.updateIndicators();
        this.updateTariffs();
    },

    updateIndicators: function() {
        let models = this.options.tenant.indicatorsByUtilityKind(this.selectedUtilityKind);
        this.options.indicators.reset();
        this.options.indicators.add(models);
    },

    updateTariffs: function() {
        let models = WiseMetering.tariffs.byUtilityKind(this.selectedUtilityKind);
        this.options.tariffs.reset();
        this.options.tariffs.add(models);
    },

    timezone: function() {
        return this.model.timezone();
    },

    afterSave: function() {
        if (this.options.active !== this.model.get('active')) {
            this.collection.remove(this.model);
        }
    }
});
