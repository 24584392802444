WiseMetering.Views.ObjectivesAccordion = Backbone.Marionette.ItemView.extend({
    template: 'templates/ui/layout/empty',

    consumptionCost: function(type, utility_kind_name) {
        if (type === 'cost') {
            return WiseMetering.getCurrency();
        } else {
            if (utility_kind_name === 'Electricity') {
                return 'kWh';
            } else if (utility_kind_name === 'Gas Energy') {
                return 'kWh';
            } else if (utility_kind_name === 'Hot Water Energy') {
                return 'kWh';
            } else if (utility_kind_name === 'Cooled Water Energy') {
                return 'kWh';
            } else if (utility_kind_name === 'Gas Volume') {
                return 'm³';
            } else if (utility_kind_name === 'Water') {
                return 'm³';
            } else if (utility_kind_name === 'Oil') {
                return 'm³';
            }
        }
    },

    onClose: function() {
        if (this.table && this.table.remove && typeof (this.table.remove) === 'function') {
            this.table.remove();
        }
    },

    onRender: function() {
        const collection = this.model.objectives();

        const monthNames = i18next.t('months', { returnObjects: true });

        this.table = new WiseMetering.Views.Table({
            id: 'objectives',
            collection: collection,
            modelSerializer: model => {
                const json = model.toJSON(),
                      utilityKind =  WiseMetering.utilityKinds.get(json.utility_kind_id);

                json.utilityKindName = i18next.t(`utility_kinds.${utilityKind.get('slug')}`).capitalize();
                json.type = this.consumptionCost(json.analysis_type, WiseMetering.utilityKinds.get(json.utility_kind_id).get('name'));
                json.jan = formatValue(json.jan, '', 0);
                json.feb = formatValue(json.feb, '', 0);
                json.mar = formatValue(json.mar, '', 0);
                json.apr = formatValue(json.apr, '', 0);
                json.may = formatValue(json.may, '', 0);
                json.jun = formatValue(json.jun, '', 0);
                json.jul = formatValue(json.jul, '', 0);
                json.aug = formatValue(json.aug, '', 0);
                json.sep = formatValue(json.sep, '', 0);
                json.oct = formatValue(json.oct, '', 0);
                json.nov = formatValue(json.nov, '', 0);
                json.dec = formatValue(json.dec, '', 0);
                return json;
            },
            columns: {
                year: i18next.t('common.year'),
                utilityKindName: i18next.t('glossary.utility_kind'),
                type: {
                    title: i18next.t('common.type'),
                    className: 'center'
                },
                jan: {
                    title: monthNames[0],
                    className: 'align-right'
                },
                feb: {
                    title: monthNames[1],
                    className: 'align-right'
                },
                mar: {
                    title: monthNames[2],
                    className: 'align-right'
                },
                apr: {
                    title: monthNames[3],
                    className: 'align-right'
                },
                may: {
                    title: monthNames[4],
                    className: 'align-right'
                },
                jun: {
                    title: monthNames[5],
                    className: 'align-right'
                },
                jul: {
                    title: monthNames[6],
                    className: 'align-right'
                },
                aug: {
                    title: monthNames[7],
                    className: 'align-right'
                },
                sep: {
                    title: monthNames[8],
                    className: 'align-right'
                },
                oct: {
                    title: monthNames[9],
                    className: 'align-right'
                },
                nov: {
                    title: monthNames[10],
                    className: 'align-right'
                },
                dec: {
                    title: monthNames[11],
                    className: 'align-right'
                }
            },
            toolbar_actions: {
                new: {
                    view_call: () => new WiseMetering.Views.ManageObjective({
                        collection: WiseMetering.objectives,
                        model: new WiseMetering.Model.Objective({ zone_id: this.model.id }),
                        type: 'new'
                    })
                },
                edit: {
                    view_call: model => new WiseMetering.Views.ManageObjective({ model, type: 'edit' })
                },
                delete: {
                    view_call: model => new WiseMetering.Views.DestroyObjective({ model })
                }
            },
            filter: false,
            info: false,
            length: false,
            paginate: false,
            sort: {
                column: 'year',
                order: 'desc'
            }
        });

        this.$el.html(this.table.el);

        this.listenTo(WiseMetering.objectives, 'add', function(objective) {
            if (objective.get('zone_id') === this.model.id) {
                collection.add(objective);
            }
        });
    }
});
