WiseMetering.Model.ZoneAttributeValue = WiseMetering.Model.extend({
    urlRoot: '/zone_attribute_values',

    defaults: {
        'values': []
    },

    bindChange: function() {
        this.on('change:values', function() {
            if (this.zone()) {
                this.zone().trigger('change:changeAttributeValue', this);
            }
        });
    },

    zoneAttribute: function() {
        return WiseMetering.zone_attributes.get(this.get('zone_attribute_id'));
    },

    zone: function() {
        return WiseMetering.zones.get(this.get('zone_id'));
    },

    zoneAttributeName: function() {
        return this.zoneAttribute().get('name');
    },

    symbol: function() {
        return this.zoneAttribute().symbol();
    },

    currentValue: function() {
        return _.last(this.get('values')).value;
    }
});
