module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<dt>\n    <label for=\'etl_dsn\'>' +
((__t = ( i18next.t('etls.cpe') )) == null ? '' : __t) +
'</label>\n</dt>\n<dd>\n    <input class=\'text\' id=\'etl_dsn\' name=\'etl[dsn]\' placeholder=\'' +
((__t = ( i18next.t('etls.cpe_placeholder') )) == null ? '' : __t) +
'\'>\n</dd>\n<dt>\n    <label for=\'etl_building\'>' +
((__t = ( i18next.t('glossary.building') )) == null ? '' : __t) +
'</label>\n</dt>\n<dd>\n    <select class=\'text\' id=\'etl_building\' name=\'etl[building_id]\'>\n        ';
 buildings.each(function(building) { ;
__p += '\n                <option value="' +
((__t = ( building.get('id') )) == null ? '' : __t) +
'">\n                    ' +
((__t = ( building.get('name') )) == null ? '' : __t) +
'\n                </option>\n        ';
 }); ;
__p += '\n    </select>\n</dd>\n<input class=\'text\' id=\'etl_username\' name=\'etl[username]\' value="' +
((__t = ( username )) == null ? '' : __t) +
'" hidden>\n<input class=\'text\' id=\'etl_secret\' name=\'etl[secret]\' value="' +
((__t = ( secret )) == null ? '' : __t) +
'" hidden>\n<input class=\'text\' id="etl_start_at" maxlength="10" type="text" name=\'etl[start_at]\' value="' +
((__t = ( start_at )) == null ? '' : __t) +
'" hidden>\n<input class=\'text\' id="etl_rate" type="number" name=\'etl[rate]\' value="' +
((__t = ( rate )) == null ? '' : __t) +
'" hidden>\n<input class=\'text\' id="etl_mode" type="text" name=\'etl[mode]\' value="' +
((__t = ( mode )) == null ? '' : __t) +
'" hidden>\n\n';

}
return __p
}