WiseMetering.Model.ZoneAttribute = WiseMetering.Model.extend({
    urlRoot: '/zone_attributes',

    unit: function() {
        return WiseMetering.units.get(this.get('unit_id'));
    },

    symbol: function() {
        return this.unit().get('symbol');
    }
});
