WiseMetering.Views.OrganizationDetailsTab = Backbone.Marionette.Layout.extend({
    className: 'dashboard full-height',
    template: 'templates/organizations/details_tab',

    regions: {
        details: '#details-accordion',
        summaryInfo: '#summary-info',
        specialFeatures: '#special-features-accordion',
        system: '#system-info-accordion',
        tiers: '#tier-accordion'
    },

    onRender: function() {
        this.summaryInfo.show(new WiseMetering.Views.OrganizationSummaryInfo({ model: this.model }));
        this.details.show(new WiseMetering.Views.OrganizationDetailsAccordion({ model: this.model }));
        this.tiers.show(new WiseMetering.Views.OrganizationTiersAccordion({ model: this.model }));
        if (this.model.get('type') !== 'retail') {
            this.specialFeatures.show(new WiseMetering.Views.OrganizationSpecialFeaturesAccordion({ model: this.model }));
        }
        this.system.show(new WiseMetering.Views.SystemInfoAccordion({ model: this.model }));
    }
});
