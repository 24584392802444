WiseMetering.Views.IndexUser = WiseMetering.Views.TableIndex.extend({
    icon: 'users',
    name: () => i18next.t('glossary.users'),

    createTable: function() {
        let users = new WiseMetering.Collection.Users(WiseMetering.users.filter(user => user.get('name') !== 'System'));

        return new WiseMetering.Views.Table({
            id: 'users-table',
            collection: users,
            modelSerializer: function(model) {
                const
                    json = model.toJSON(),
                    building = model.buildings(),
                    groups = model.groups();

                json.lastLogin = model.get('last_login_at') ? WiseMetering.utils.formatDate(model.get('last_login_at')) : '-';
                json.lastSeen = model.get('last_seen_at') ? WiseMetering.utils.formatDate(model.get('last_seen_at')) : '-';
                json.buildings = building.length;
                json.groups = groups.pluck('name').join(', ');
                json.role = model.role();
                if (json.enabled === false) {
                    json.state = {
                        color: 'red',
                        title: i18next.t('states.inactive')
                    };
                } else if (building.length) {
                    json.state = {
                        color: 'green',
                        title: i18next.t('glossary.active')
                    };
                } else {
                    json.state = {
                        color: 'orange',
                        title: i18next.t('states.warning')
                    };
                }
                return json;
            },
            columns: {
                state: {
                    mRender: ({ color, title }) => JST['templates/ui/components/circle']({
                        color: WiseMetering.SemaphorColors[color],
                        title
                    }),
                    sWidth: '20px'
                },
                name: i18next.t('common.name'),
                email: 'Email',
                role: i18next.t('glossary.role'),
                lastLogin: i18next.t('users.last_login'),
                lastSeen: i18next.t('users.last_seen'),
                buildings: i18next.t('glossary.buildings'),
                groups: i18next.t('glossary.groups')
            },
            toolbar_actions: {
                show: {
                    view_call: model => new WiseMetering.Views.ShowUser({ model })
                },
                new: {
                    permission: 'administrator',
                    view_call: () => new WiseMetering.Views.NewUser({
                        collection: users,
                        model: new WiseMetering.Model.User
                    })
                },
                edit: {
                    permission: 'administrator',
                    view_call: model => new WiseMetering.Views.EditUser({ model })
                },
                disable: {
                    icon: 'disable',
                    permission: 'administrator',
                    view_call: model => {
                        if (!model.get('enabled')) {
                            return;
                        }

                        new WiseMetering.Views.DisableUser({ model: model, callback: () => this.table.uncheckAll() });
                    }
                },
                resetPassword: {
                    icon: 'resetPassword',
                    label: i18next.t('ui.actions.reset_password'),
                    permission: 'administrator',
                    view_call: model => {
                        if (!model.get('enabled')) {
                            return;
                        }

                        new WiseMetering.Views.ResetPassword({ model: model, callback: () => this.table.uncheckAll() });
                    }
                },
                delete: {
                    permission: 'administrator',
                    view_call: model => new WiseMetering.Views.DeleteUser({ model })
                }
            }
        });
    }
});
