WiseMetering.Collection.Indicators = WiseMetering.Collection.extend({
    hasOrganization: true,
    model: WiseMetering.Model.Indicator,
    url: '/indicators',

    etl: function() {
        return new WiseMetering.Collection.Indicators(this.where({ type: 'etl' }));
    },

    globalIndicators: function() {
        return new WiseMetering.Collection.Indicators(this.filter(function(i) {
            const type = i.get('type');
            return i.get('building_id') === null && (type === 'complex' || type === 'raw');
        }));
    },

    weatherApiIndicators: function() {
        return new WiseMetering.Collection.Indicators(this.where({ type: 'weather' }));
    },

    degreeDaysIndicators: function() {
        return new WiseMetering.Collection.Indicators(this.where({ type: 'degree-day' }));
    },

    raw: function() {
        return new WiseMetering.Collection.Indicators(this.where({ type: 'raw' }));
    },

    indicatorsByUtilityKind: function( utilityKindSlug ) {
        let kindSlug = utilityKindSlug === 'electricity' ? 'active_energy' : utilityKindSlug;
        const indicatorKind = WiseMetering.indicatorKinds.findWhere({ slug: kindSlug });

        return WiseMetering.indicators.where({kind_id: indicatorKind.id})
    }

});
