WiseMetering.Views.Ui.Widget.Table = Backbone.View.extend({
    templateID: 'templates/ui/widgets/table',

    build: function(){
    },

    render: function() {
        let aaData;

        if (this.options.modelSerializer && this.collection.length > 0) {
            aaData = this.collection.map(this.options.modelSerializer);
        } else if (this.options.arrayData && this.options.arrayData.length > 0) {
            aaData = this.options.arrayData;
        } else {
            aaData = [];
        }

        this.$el = $(JST[this.templateID]({
            title: this.options.title,
            height: this.options.height,
            rows: aaData,
            headers: this.options.headers || null,
        }));

        $(this.options.el).append(this.$el);
    },

    close: function() {
        this.$el.remove();
    }
});
