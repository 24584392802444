WiseMetering.Views.EditUtility = WiseMetering.Views.Modal.Save.extend({
    template: 'templates/utilities/edit',
    title: () => i18next.t('modal.title.utilities.edit'),
    form_prefix: 'utility',
    className: 'mbx-info text-left',
    ui: {
        extra: '#utility-extra'
    },

    onShow: function() {
        const utilityKind = this.model.utilityKind();

        if (utilityKind.get('slug') !== 'electricity') {
            return;
        }

        if (!this.model.get('capacitive_energy_id')) {
            this.addCapacitive();
        }

        if (!this.model.get('inductive_energy_id')) {
            this.addInductive();
        }
    },

    addCapacitive: function() {
        const template = `
            <dt>
                <label for='utility-capacitive-energy'>${i18next.t('indicatorKinds.capacitive_energy')}</label>
            </dt>
            <dd>
                <select class='text' id='utility-capacitive-energy' name='utility[capacitive_energy_id]'></select>
            </dd>
        `;

        this.ui.extra.append(template);

        const capacitiveIndicators = WiseMetering.indicators.where({ building_id: this.model.get('building_id'), kind_slug: 'capacitive_energy' });
        this.$('#utility-capacitive-energy').html(WiseMetering.utils.optionsFromModels(capacitiveIndicators, 'name', null, true));
    },

    addInductive: function() {
        const template = `
            <dt>
                <label for='utility-inductive-energy'>${i18next.t('indicatorKinds.inductive_energy')}</label>
            </dt>
            <dd>
                <select class='text' id='utility-inductive-energy' name='utility[inductive_energy_id]'></select>
            </dd>
        `;

        this.ui.extra.append(template);

        const inductiveIndicators = WiseMetering.indicators.where({ building_id: this.model.get('building_id'), kind_slug: 'inductive_energy' });
        this.$('#utility-inductive-energy').html(WiseMetering.utils.optionsFromModels(inductiveIndicators, 'name', null, true));
    }
});
