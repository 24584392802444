WiseMetering.Views.Ui.Widget.NoData = Backbone.View.extend({
    templateID: 'templates/ui/widgets/no_data',
    message: '',

    build: function(){
    },

    render: function() {
        this.$el = $(JST[this.templateID]({
            height: this.options.height,
            message: this.options.message,
            organizationId: WiseMetering.currentOrganization.get('id'),
            title: this.options.title
        }));

        $(this.options.el).append(this.$el);
    },

    close: function() {
        this.$el.remove();
    }
});
