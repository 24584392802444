module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class=\'basic-widget grid-' +
((__t = ( widgetSize.gridSize )) == null ? '' : __t) +
'\'>\n    <div class=\'title\'>\n        <div class="title-text">\n            ' +
((__t = ( title )) == null ? '' : __t) +
'\n        </div>\n        <span class=\'basic-widget-options\' id=\'widget-options-global\'>' +
((__t = ( WiseMetering.icon('gear') )) == null ? '' : __t) +
'</span>\n    </div>\n    <div class=\'widget-content\'>\n        ';
 if(highlightArea){ ;
__p += '\n            <div class="highlight-value"  style="background-color: ' +
((__t = ( highlightArea.backgroundColor )) == null ? '' : __t) +
'; color: ' +
((__t = ( highlightArea.textColor )) == null ? '' : __t) +
'">\n                ' +
((__t = ( WiseMetering.icon(highlightArea.icon) )) == null ? '' : __t) +
' ' +
((__t = ( highlightArea.value )) == null ? '' : __t) +
'\n            </div>\n        ';
 } ;
__p += '\n        <div class=\'chart\' id=\'' +
((__t = ( id )) == null ? '' : __t) +
'\'></div>\n    </div>\n</div>\n';

}
return __p
}