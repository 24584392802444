import Messages from '@pages/Messages';
import React from 'react';
import { createRoot } from 'react-dom/client';

WiseMetering.Views.IndexMessages = Backbone.Marionette.View.extend({
    id: 'messages-container',

    onShow: function() {
        WiseMetering.messages.fetch();
        const container = document.getElementById(this.id);
        const root = createRoot(container);
        root.render(<Messages messages={WiseMetering.messages}/>);
    }
});
