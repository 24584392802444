WiseMetering.Views.NewZoneAttribute = WiseMetering.Views.Modal.Save.extend({
    template: 'templates/zone_attributes/new',
    className: 'mbx-info text-left',
    form_prefix: 'zone_attribute',
    title: 'New Zone Attribute',

    ui: {
        enumerator_setup: '.enumerator_setup',
        new_enumerator_member: '.new_member',
        enumerator_list: '.enumerator_list'
    },

    events: {
        'change select#zone_attribute_metadata_type': 'changeMetadataType',
        'click a.new_enumerator_member': 'newEnumeratorMemberShow',
        'keyup .new_member': 'addMember',
        'click a.mbx_btn_close': 'removeMember'
    },

    onRender: function() {
        this.ui.enumerator_list.sortable();
    },

    addMember: function(event) {
        if ((event.keyCode || event.which) === 13) {
            var value = $(event.currentTarget).val();
            // TODO: Refactor this piece of shit
            var enumeratorMember = '';
            enumeratorMember += '<li class="member" style="width: 400px;float:none;padding:5px;background:rgba(225,230,236,0.5);margin-bottom:1px;border:1px solid #ccc;">';
            enumeratorMember += '<span style="z-index:1;">' + value + '</span>';
            enumeratorMember += '<a style="position:relative;top:-2px;right:2px;" href="#" class="mbx_btn_close"></a>';
            enumeratorMember += '<input type="hidden" name="zone_attribute[enumerator][]" value="' + value + '" />';
            enumeratorMember += '</li>';
            this.ui.enumerator_list.append(enumeratorMember);
            this.ui.new_enumerator_member.val(null);
            this.ui.new_enumerator_member.hide();
            this.ui.new_enumerator_member.parent().find('label#press_enter').remove();
        }
    },

    removeMember: function(event) {
        $(event.currentTarget).parent('li:first').remove();
    },

    newEnumeratorMemberShow: function() {
        this.ui.new_enumerator_member.show();
        this.ui.new_enumerator_member.parent().append('<label id="press_enter">Press enter</label>');
        this.ui.new_enumerator_member.focus();
        this.modal.redraw();
        return false;
    },

    changeMetadataType: function(event) {
        if ($(event.currentTarget).find(':selected').text().toLowerCase() === 'enumerator') {
            this.ui.enumerator_setup.show();
            this.ui.new_enumerator_member.hide();
        } else {
            this.ui.enumerator_setup.hide();
        }
    }
});
