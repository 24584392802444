module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<dl class=\'stepped zero\'>\n    <dt>ID</dt>\n    <dd>' +
((__t = ( id )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( name )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('glossary.active') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( active ? i18next.t('common.yes') : i18next.t('common.no') )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('etls.database_dsn') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( dsn )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('etls.connection_username') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( username )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('etls.driver') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( etl_driver_name )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('glossary.rate') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( rate )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('glossary.created_by') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( creator_name )) == null ? '' : __t) +
', ' +
((__t = ( created_at )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('glossary.updated_by') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( updater_name )) == null ? '' : __t) +
', ' +
((__t = ( updated_at )) == null ? '' : __t) +
'</dd>\n    <dt>' +
((__t = ( i18next.t('glossary.last_run') )) == null ? '' : __t) +
'</dt>\n    <dd>' +
((__t = ( last_run )) == null ? '' : __t) +
'</dd>\n    ';
 if (indicators.length) { ;
__p += '\n        <dt>' +
((__t = ( i18next.t('glossary.indicators') )) == null ? '' : __t) +
'</dt>\n        ';
 indicators.forEach(function(indicator) { ;
__p += '\n            <dd>\n                <a target="_blank" href="' +
((__t = ( indicator.url )) == null ? '' : __t) +
'">\n                    ' +
((__t = ( indicator.name )) == null ? '' : __t) +
'\n                </a>\n            </dd>\n        ';
 }) ;
__p += '\n    ';
 } ;
__p += '\n</dl>\n';

}
return __p
}