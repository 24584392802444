module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div style=\'margin-top: 5px;\'>\n  <input class=\'zone_attribute_value_from\' maxlength=\'10\' name=\'zone_attribute_value[values][][from]\' placeholder=' +
((__t = ( i18next.t('common.from') )) == null ? '' : __t) +
' type=\'text\' value="' +
((__t = ( value.from ? moment.tz(value.from * 1000, timezone).format('DD/MM/YYYY') : '' )) == null ? '' : __t) +
'">\n  <input class=\'zone_attribute_value_to\' maxlength=\'10\' name=\'zone_attribute_value[values][][to]\' placeholder=' +
((__t = ( i18next.t('common.to') )) == null ? '' : __t) +
' type=\'text\' value="' +
((__t = ( value.to ? moment.tz(value.to * 1000, timezone).format('DD/MM/YYYY') : ''
  )) == null ? '' : __t) +
'">\n  <select class=\'zone_attribute_value\' name=\'zone_attribute_value[values][][value]\'>\n    ';
 _.each(enumerator, function(member){ ;
__p += '\n    <option>' +
((__t = ( member )) == null ? '' : __t) +
'</option>\n    ';
 }); ;
__p += '\n  </select>\n  ' +
((__t = ( symbol )) == null ? '' : __t) +
'\n</div>\n';

}
return __p
}