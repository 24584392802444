module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class=\'accordion-group\'>\n    <div class=\'accordion-heading\'>\n        <div class=\'accordion-toggle\'>\n            <div class=\'svg_icon\'>\n                ' +
((__t = ( WiseMetering.icon('attributes') )) == null ? '' : __t) +
'\n            </div>\n            ' +
((__t = ( i18next.t('common.attributes') )) == null ? '' : __t) +
'\n            ';
 if(WiseMetering.currentUser.get('manager')){ ;
__p += '\n                <div class=\'wmui-btn\' id=\'manage_attributes\' style=\'float: right;\'>' +
((__t = ( i18next.t('ui.buttons.manage') )) == null ? '' : __t) +
'</div>\n            ';
 } ;
__p += '\n        </div>\n    </div>\n    <div class=\'accordion-body\'>\n        <div class=\'accordion-inner\'>\n            <div class=\'per-box\'>\n                <dl>\n                    ';
 if (attributes.length === 0) { ;
__p += '\n                        ' +
((__t = ( i18next.t('ui.no_attributes').capitalize() )) == null ? '' : __t) +
'\n                    ';
 } else { ;
__p += '\n                        ';
 _(attributes).each(function(attribute){ ;
__p += '\n                            <dt>' +
((__t = ( attribute.name )) == null ? '' : __t) +
':</dt>\n                            <dd>' +
((__t = ( formatValue(attribute.value, attribute.symbol === 'N/A' ? null : attribute.symbol) )) == null ? '' : __t) +
'</dd>\n                        ';
 }); ;
__p += '\n                    ';
 } ;
__p += '\n                </dl>\n            </div>\n        </div>\n    </div>\n</div>\n';

}
return __p
}