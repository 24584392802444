WiseMetering.Views.Objectives = Backbone.Marionette.Layout.extend({
    template: 'templates/zones/objectives',
    className: 'dashboard full-height',

    regions: {
        table: '#table'
    },

    bindObjectiveChanges: function() {
        const doBind = type => data => this.listenTo(data, type, () => this.redrawWidget());

        doBind('add')(WiseMetering.objectives);
        doBind('remove')(WiseMetering.objectives);
        getCurrentObjectives(this.model.id).forEach(doBind('change'));
    },

    unbindObjectiveChanges() {
        this.stopListening(WiseMetering.objectives, 'add');
        this.stopListening(WiseMetering.objectives, 'remove');

        getCurrentObjectives(this.model.id).forEach(model => {
            this.stopListening(model, 'change');
        });
    },

    onRender: function() {
        this.table.show(new WiseMetering.Views.ObjectivesAccordion({ model: this.model }));
        this.prepareWidget();
    },

    onShow: function() {
        this.renderWidget();
        this.bindObjectiveChanges();
    },

    onClose: function() {
        Object.values(this.widgets).forEach(w => w.close());
        this.unbindObjectiveChanges();
    },

    prepareWidget: function() {
        this.widgets = {};
        const utility_kinds = [
            { name: 'electricity', unit: 'Wh' },
            { name: 'water', unit: 'm³' },
            { name: 'oil', unit: 'm³' },
            { name: 'gas_energy', unit: 'Wh' },
            { name: 'gas_volume', unit: 'm³' },
            { name: 'cooled_water_energy', unit: 'Wh' },
            { name: 'hot_water_energy', unit: 'Wh' }
        ];

        const buildWidget = (data, title, unit) => {
            const el = `#widgets`;
            return new WiseMetering.Views.Ui.Widget.Objective({ el, data, title, unit, period: 'ytd' });
        };

        utility_kinds.forEach((utility_kind) => {
            ['consumption', 'cost'].forEach(function(analysisType) {
                const unit = analysisType === 'cost' ? null : utility_kind.unit,
                    title = `${i18next.t('glossary.objectives').capitalize()} - ${utility_kind.name.splitCapitalize('_')} ${analysisType.capitalize()}`,
                    data = WiseMetering.Analytics.getObjectiveAnalysis(this.model.id, utility_kind.name, unit);

                if (_.isEmpty(data)) return;

                this.widgets[`${utility_kind.name}_objective_${analysisType}`] = buildWidget(
                    data, title, unit
                );
            }.bind(this));
        });
    },

    renderWidget: function() {
        Object.values(this.widgets).forEach(w => w.render(false));
        this.$('#widgets').append('<div class="ghost-item">');
        Lmit.Utils.triggerResize();
        Object.values(this.widgets).forEach(w => w.drawChart());
    },

    redrawWidget: function() {
        this.$el.find('#widgets').empty();
        this.prepareWidget();
        this.renderWidget();
    }

});

const getCurrentObjectives = building_id => {
    const year = moment().year();
    return WiseMetering.objectives.where({ year, building_id });
};
