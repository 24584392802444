WiseMetering.Model.User = WiseMetering.Model.extend({
	urlRoot: '/users',

	buildings: function() {
		if (this.get('administrator')) {
			return WiseMetering.zones.buildings();
		}
		return WiseMetering.groups.reduce((buildings, group) => {
			if (group.get('user_ids').includes(this.id)) {
				buildings.add(group.buildings().models);
			}
			return buildings;
		}, new WiseMetering.Collection.Zones);
	},

	disable: function(success, error) {
		const options = { error, success, type: 'PUT', url: this.url() + '/disable' };

		return Backbone.sync.call(this, null, this, options);
	},

	getInitialsName: function() {
		let initial = [];
		this.shortName().split(' ').forEach(name => initial.push(name.split('')[0]));
		return initial.join('').toUpperCase();
	},

	groups: function() {
		return WiseMetering.groups.reduce((groups, group) => {
			if (group.get('user_ids').includes(this.id)) {
				groups.add(group);
			}
			return groups;
		}, new WiseMetering.Collection.Groups);
	},

    isAdmin: function() {
        return this.get('administrator');
    },

	isSuperUser: function() {
		return this.get('superuser');
	},

	isConfigUser: function() {
		return this.get('config');
	},

    isLoggedInUser: function() {
        return this.id === WiseMetering.currentUser.id;
    },

	preferences: function() {
		return WiseMetering.userPreferences;
	},

	resetPassword: function(success, error) {
		const options = { error, success, type: 'PUT', url: this.url() + '/reset_password' };

		return Backbone.sync.call(this, null, this, options);
	},

	role: function() {
		if (this.get('administrator')) {
			return i18next.t('glossary.administrator');
		} else if (this.get('manager')) {
			return i18next.t('glossary.manager');
		} else if (this.get('integration')) {
			return i18next.t('glossary.integration');
		} else {
			return i18next.t('glossary.user');
		}
	},

	shortName() {
		const names = this.get('name').split(' ');
		return names.length > 1 ? [names.shift(), names.pop()].join(' ') : names.shift();
	}
});
