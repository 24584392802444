import moment from 'moment-timezone';
import queryString from 'query-string';

WiseMetering.utils = {
    addLoader: function(element, top = 0, bottom = 0, width = 'auto') {
        $(element).html(`<img class='wise-loader' src='/images/wisemetering/loader.svg' style='margin-top: ${top}px; margin-bottom: ${bottom}px; max-width: ${width}px'>`);
    },

    convertObjectStringToNumber: function(object) {
        const response = {};

        for (const [key, value] of Object.entries(object)) {
            if (_.isArray(value)) {
                response[key] = value.map(v => this.convertObjectStringToNumber(v));
            } else if (_.isObject(value)) {
                response[key] = this.convertObjectStringToNumber(value);
            } else if (value === '') {
                response[key] = null;
            } else if (value.match(/[+-]?([0-9]*[.])?[0-9]+/)) {
                response[key] = parseFloat(value);
            } else {
                response[key] = value;
            }
        }

        return response;
    },

    formatDate: (epoch, timezone = null, format = 'YYYY/MM/DD HH:mm:ss') => {
        timezone ??= Intl.DateTimeFormat().resolvedOptions().timeZone;

        return moment.unix(epoch).tz(timezone).format(format);
    },

    getColorByState: function(state) {
        switch (state) {
            case 'completed':
                return WiseMetering.SemaphorColors['green'];
            case 'ongoing':
                return WiseMetering.SemaphorColors['orange'];
            case 'pending':
                return WiseMetering.SemaphorColors['blue'];
            case 'discarded':
                return WiseMetering.SemaphorColors['red'];
            default:
                return WiseMetering.SemaphorColors['yellow'];
        }
    },

    humanizeText: function(text) {
        return text.replace(/_/g, ' ').replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });
    },

    optionsFromArray: function(array, currentValue = null, id = 'id', value = 'name', includePleaseSelect = false) {
        let options = '';

        if (includePleaseSelect) {
            options += `<option value=''>${i18next.t('ui.please_select_option')}</option>`;
        }

        array.forEach(entry => {
            options += `<option value='${entry[id]}' ${entry[id] ? '' : 'disabled'} ${entry[id] === currentValue || !entry[id] ? 'selected' : ''}>`;
            options += entry[value];
            options += '</option>';
        });

        return options;
    },

    optionsFromModels: function(models, param = 'name', currentValue = null, includePleaseSelect = false) {
        const array = models.map(model => ({ id: model.id, name: model.get(param) }));
        return WiseMetering.utils.optionsFromArray(array, currentValue, 'id', 'name', includePleaseSelect);
    },

    pad2: function(number) {
        return (number < 10 ? '0' : '') + number;
    },

    replaceQueryString: function(newParams) {
        const
            params = queryString.stringify(newParams),
            url = Backbone.history.fragment.split('?').at(0);

        if (!params) {
            return url;
        }

        return `/${url}?${params}`;
    },

    searchableText: function(name) {
        // Taken from http://stackoverflow.com/questions/990904/javascript-remove-accents-in-strings
        name = name.toLowerCase();
        name = name.replace(new RegExp(/[àáâãäå]/g), 'a');
        name = name.replace(new RegExp(/æ/g), 'ae');
        name = name.replace(new RegExp(/ç/g), 'c');
        name = name.replace(new RegExp(/[èéêë]/g), 'e');
        name = name.replace(new RegExp(/[ìíîï]/g), 'i');
        name = name.replace(new RegExp(/ñ/g), 'n');
        name = name.replace(new RegExp(/[òóôõö]/g), 'o');
        name = name.replace(new RegExp(/œ/g), 'oe');
        name = name.replace(new RegExp(/[ùúûü]/g), 'u');
        name = name.replace(new RegExp(/[ýÿ]/g), 'y');

        return name;
    },

    toSnakeCase: function(str) {
        const normalizedString = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        return normalizedString.toLowerCase().replace(/[\W_]+/g, '_').replace(/_+/g, '');
    },

    titleSuffix: function(useArea, period) {
        const finalPeriod = period === 'mtd' ? i18next.t('periods.mtd') : i18next.t('periods.ytd'),
            perSqmSuffix = useArea ? ` ${i18next.t('common.per')} m²` : '';

        return `${finalPeriod.toUpperCase()}${perSqmSuffix}`;
    }
};
