WiseMetering.Views.EditUserPreferences = WiseMetering.Views.Modal.Save.extend({
    className: 'mbx-info text-left',
    form_prefix: 'user_preferences',
    input_readers: [{
        type: 'checkbox', func: function(el) {
            return el.prop('checked');
        }
    }],
    template: 'templates/user_preferences/edit',
    title: () => i18next.t('modal.title.user_preferences.edit'),
    modelEvents: {
        'change:language': 'onChangeLanguage'
    },

    serializeData: function() {
        const data = this.model.toJSON();
        data.availableLanguages = [{ value: 'en', label: 'English' }, { value: 'es', label: 'Español' }, { value: 'pt', label: 'Português' }];
        return data;
    },

    onChangeLanguage: function() {
        window.location.reload();
    }
});
