module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        ';
 if (integration) { ;
__p += '\n            <dt style="text-align: center">\n                ' +
((__t = ( i18next.t('users.integration_users_cannot_be_changed') )) == null ? '' : __t) +
'\n            </dt>\n        ';
 } else { ;
__p += '\n            <dt>\n                <label for=\'user_name\'>' +
((__t = ( i18next.t('common.name') )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd>\n                <input class=\'text\' id=\'user_name\' name=\'user[name]\' type=\'text\' value=\'' +
((__t = ( name )) == null ? '' : __t) +
'\'>\n            </dd>\n            <dt>\n                <label for=\'user_email\'>' +
((__t = ( i18next.t('common.email') )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd>\n                <input class=\'text\' id=\'user_email\' name=\'user[email]\' type=\'text\' value=\'' +
((__t = ( email )) == null ? '' : __t) +
'\'>\n            </dd>\n            <dt>\n                <label for=\'user_password\'>' +
((__t = ( i18next.t('common.password') )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd>\n                <input class=\'text\' id=\'user_password\' name=\'user[password]\' type=\'password\'>\n            </dd>\n            <dt>\n                <label for=\'user_password_confirmation\'>' +
((__t = ( i18next.t('glossary.password_confirmation') )) == null ? '' : __t) +
'</label>\n            </dt>\n            <dd>\n                <input class=\'text\' id=\'user_password_confirmation\' name=\'user[password_confirmation]\' type=\'password\'>\n            </dd>\n            ';
 if (WiseMetering.currentUser.isAdmin() && !myProfile) { ;
__p += '\n                <dt style=\'margin-bottom: 0.5em;\'>\n                    <input ' +
((__t = ( administrator ? 'checked' : '' )) == null ? '' : __t) +
' class=\'vertical-middle\' id=\'user_administrator\' name=\'user[administrator]\' type=\'checkbox\'>\n                    <label for=\'user_administrator\'>' +
((__t = ( i18next.t('glossary.administrator') )) == null ? '' : __t) +
'</label>\n                </dt>\n                <dt style=\'margin-bottom: 0.5em;\'>\n                    <input ' +
((__t = ( manager ? 'checked' : '' )) == null ? '' : __t) +
' class=\'vertical-middle\' id=\'user_manager\' name=\'user[manager]\' type=\'checkbox\'>\n                    <label for=\'user_manager\'>' +
((__t = ( i18next.t('glossary.manager') )) == null ? '' : __t) +
'</label>\n                </dt>\n            ';
 } ;
__p += '\n        ';
 } ;
__p += '\n    </dl>\n</form>\n';

}
return __p
}