import moment from 'moment-timezone';

WiseMetering.Views.ZoneAttributesManage = WiseMetering.Views.Modal.Custom.extend({
    template: 'templates/zone_attribute_values/manage',
    form_prefix: 'zone_attribute_value',
    title: () => i18next.t('modal.title.zone_attributes.manage'),

    width: 650,
    resize: false,
    secondaryTemplate: null,

    buttons: ['save', 'cancel'],

    events: {
        'change #zone_attribute_value_zone_attribute_id': 'showValues',
        'click #add_value': 'addValue',
        'click #remove_value': 'removeValue'
    },

    serializeData: function() {
        return {
            zone_id: this.zone.id
        };
    },

    onRender: function() {
        this.$('select#zone_attribute_value_zone_attribute_id').append(WiseMetering.utils.optionsFromModels(WiseMetering.zone_attributes.models, 'name'));
    },

    showValues: function() {
        var that = this;
        // Clean the view and find the new attribute - if none, do nothing
        that.$('#zone_attribute_type').empty();
        var selected = WiseMetering.zone_attributes.get(that.$('select#zone_attribute_value_zone_attribute_id').val());
        if (!selected) return;
        // Find the attribute type
        switch (selected.get('metadata_type')) {
            case 'float':
            case 'number':
            case 'text':
                that.secondaryTemplate = 'templates/zone_attribute_values/number';
                break;
            case 'boolean':
                that.secondaryTemplate = 'templates/zone_attribute_values/boolean';
                break;
            case 'enumerator':
                that.secondaryTemplate = 'templates/zone_attribute_values/enumerator';
                break;
        }

        // Find the model or instantiate a new one
        that.model = this.zone.zone_attributes().where({ zone_attribute_id: that.$('select#zone_attribute_value_zone_attribute_id').val() })[0] || new WiseMetering.Model.ZoneAttributeValue;
        that.modal.model = that.model;

        const timezone = this.zone.get('timezone');
        // Draw the values if they exist
        if (that.model.get('values') && !_.isEmpty(that.model.get('values'))) {
            that.model.get('values').forEach((zoneAttributeValue, index) => {
                this.$('#zone_attribute_type').append(
                    that.renderSecondaryTemplate({
                        index,
                        symbol: selected.symbol(),
                        from: moment.tz(zoneAttributeValue.from * 1000, timezone).format('DD/MM/YYYY'),
                        to: zoneAttributeValue.to ? moment.tz(zoneAttributeValue.to * 1000, timezone).format('DD/MM/YYYY') : null,
                        value: zoneAttributeValue.value
                    })
                );
            });
        } else {
            that.$('#zone_attribute_type').append(that.renderSecondaryTemplate({
                index: 0,
                symbol: selected.symbol(),
                from: null,
                to: null,
                value: null
            }));
        }
        that.bindPickers();
        that.modal.redraw();
    },

    renderSecondaryTemplate: function(data) {
        return JST[this.secondaryTemplate](data);
    },

    addValue: function(from) {
        const
            index = this.model.get('values').length,
            selected = WiseMetering.zone_attributes.get(this.$('select#zone_attribute_value_zone_attribute_id').val()),
            timezone = this.zone.get('timezone');

        this.$('#zone_attribute_type').append(this.renderSecondaryTemplate({
            index,
            symbol: selected.symbol(),
            from,
            to: null,
            value: null
        }));

        this.bindPickers();
        this.modal.redraw();
    },

    bindPickers: function() {
        var that = this;
        if (that.$('.zone_attribute_value').length === 1 && that.$('.zone_attribute_value_from').val() === '') {
            that.$('.zone_attribute_value_to').attr('disabled', 'disabled');
            that.$('.zone_attribute_value_from').datepicker({
                dateFormat: 'dd/mm/yy',
                changeMonth: true,
                changeYear: true,
                onClose: function(dateText) {
                    if (dateText !== '') {
                        $(this).val(moment(dateText, 'DD/MM/YYYY').startOf('month').format('DD/MM/YYYY'));
                    }
                }
            });
            that.$('.zone_attribute_value_from').unbind('change');
            that.$('.zone_attribute_value_from').bind('change', function() {
                that.bindPickers();
            });
        } else {
            // Value
            that.$('.zone_attribute_value').attr('disabled', 'disabled');
            that.$('.zone_attribute_value:last').removeAttr('disabled');
            // From
            that.$('.zone_attribute_value_from').attr('disabled', 'disabled');
            // To
            that.$('.zone_attribute_value_to:not(:last)').attr('disabled', 'disabled');
            that.$('.zone_attribute_value_to:last').removeAttr('disabled');
            that.$('.zone_attribute_value_to:last').datepicker({
                dateFormat: 'dd/mm/yy',
                changeMonth: true,
                changeYear: true,
                minDate: moment(that.$('.zone_attribute_value_from:last').val(), 'DD/MM/YYYY').endOf('month').toDate(),
                onClose: function(dateText) {
                    if (dateText !== '') $(this).val(moment(dateText, 'DD/MM/YYYY').endOf('month').format('DD/MM/YYYY'));
                }
            });
            that.$('.zone_attribute_value_to:last').unbind('change');
            that.$('.zone_attribute_value_to:last').bind('change', function(event) {
                var to = moment($(event.target).val(), 'DD/MM/YYYY');
                var from = to.add(1, 'months').startOf('month');
                that.addValue(from.format('DD/MM/YYYY'));
            });
        }
    },

    removeValue: function() {
        if (this.$('#zone_attribute_type > div').length > 0) this.$('#zone_attribute_type > div:last').remove();
        try {
            this.$('.zone_attribute_value_to:last').val('');
            this.bindPickers();
        } catch (e) {
        }
        this.modal.redraw();
    },

    save: function() {
        const data = Backbone.Syphon.serialize(this)[this.form_prefix];

        this.block();

        if (!data.values) {
            this.model.destroy({
                wait: true,
                success: function() {
                    this.close();
                    WiseMetering.layout.showTipper('success', i18next.t('zones.attribute_removed_successfully'));
                }.bind(this),
                error: function() {
                    this.close();
                    WiseMetering.layout.showTipper('error', i18next.t('zones.attribute_not_removed'));
                }.bind(this)
            });
            return;
        }

        if (!this.model.isNew()) {
            delete data.zone_attribute_id;
            delete data.zone_id;
        }

        data.values = Object.values(data.values);

        this.model.save(data, {
            wait: true,
            patch: true,
            success: function(model) {
                if (this.collection) {
                    this.collection.add(model);
                }
                this.close();
                WiseMetering.layout.showTipper('success', i18next.t('zones.attribute_changed_successfully'));
            }.bind(this),
            error: function(model, xhr) {
                // clear possible previous errors
                this.$('label.err').removeClass('err');
                $(this.errors.el).removeClass('active').empty();
                // try to parse errors
                let errors = {};
                try {
                    errors = JSON.parse(xhr.responseText);
                } catch (e) {
                }
                // show them
                if (errors.error) {
                    $(this.errors.el).addClass('active').html(errors.error);
                }
                this.unblock();
            }.bind(this)
        });
    }
});
