WiseMetering.Views.MapBuildingPopup = Backbone.Marionette.ItemView.extend({
    template: 'templates/ui/map/buildingPopup',

    serializeData: function() {
        const { period } = WiseMetering.userPreferences.dashboardWidgets(), data = [];
        WiseMetering.utilityKinds.forEach(utility_kind => {
            const
                name = utility_kind.get('name'),
                slug = utility_kind.get('slug'),
                unit = utility_kind.unit(),
                func = period === 'ytd' ? 'currentAndLastYear' : 'currentAndHomologousMonth';

            const utilityKindData = WiseMetering.Analytics[func](this.model.id, slug);

            if (utilityKindData.current || utilityKindData.last) {
                let color = 'gray', signal = '', variance;

                if (utilityKindData.last) {
                    variance = (utilityKindData.current - utilityKindData.last) / utilityKindData.last;
                    if (variance > 0) {
                        color = 'red';
                        signal = '+';
                    } else if (variance < 0) {
                        color = 'green';
                    }
                    variance = formatValue((variance * 100).toFixed(2), '%');
                }

                if (!variance) {
                    variance = 'N/A';
                }

                data.push({
                    color,
                    current: utilityKindData.current ? formatValue(utilityKindData.current, unit) : 'N/A',
                    last: utilityKindData.last ? formatValue(utilityKindData.last, unit) : 'N/A',
                    name,
                    signal,
                    variance
                });
            }
        });

        return {
            contentHeight: this.options.contentHeight,
            id: this.model.id,
            imageUrl: this.model.get('image_url'),
            name: this.model.get('name'),
            period,
            street: this.model.get('address').street,
            data
        };
    }
});
