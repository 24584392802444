WiseMetering.Views.Ui.GlobalDashboardHeader = Backbone.View.extend({
    templateID: 'templates/ui/global_dashboard_header',

    initialize: function(params) {
        this.data = params.data;
        $.extend(this, params);
        this.id = generateUUID();
    },

    build: function(){
    },

    close: function() {
        this.$el.remove();
    },

    redraw: function() {
        if (this.draw && this.response) {
            this.$('#' + this.id).empty();
            this.draw = false;
        }
    },

    render: function() {
        this.$el = $(JST[this.templateID]({
            selectedUtilityKind: this.options.selectedUtilityKind,
            utilities: this.options.utilities
        }));
        $(this.el).append(this.$el);
    }
});
