module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<form>\n    <input name=\'zone_attribute[organization_id]\' type=\'hidden\' value=\'' +
((__t = ( WiseMetering.currentOrganization.id )) == null ? '' : __t) +
'\'>\n    <input name=\'zone_attribute[metadata_type]\' type=\'hidden\' value=\'number\'>\n    <dl class=\'stepped zero\'>\n        <dt>\n            <label for=\'zone_attribute_name\'>Name</label>\n        </dt>\n        <dd>\n            <input class=\'text\' id=\'zone_attribute_name\' name=\'zone_attribute[name]\' type=\'text\'>\n        </dd>\n        <dt>\n            <label for=\'zone_attribute_unit_id\'>Attribute Unit</label>\n        </dt>\n        <dd>\n            <select class=\'text\' id=\'zone_attribute_unit_id\' name=\'zone_attribute[unit_id]\'>\n                ';
 WiseMetering.units.each(function(unit){ ;
__p += '\n                    <option value=\'' +
((__t = ( unit.id )) == null ? '' : __t) +
'\'>' +
((__t = ( unit.get('symbol') )) == null ? '' : __t) +
'</option>\n                ';
 }) ;
__p += '\n            </select>\n        </dd>\n    </dl>\n</form>\n';

}
return __p
}