WiseMetering.icons = {
    activate: 'fas fa-thumbs-up',
    actuators: 'fas fa-toggle-on',
    add: 'fas fa-plus',
    admin: 'fas fa-wrench',
    administration: 'fas fa-user-lock',
    alarms: 'fas fa-alarm-clock',
    allocations: 'fas fa-share-alt',
    area: 'fa-solid fa-square',
    arrowDown: 'fas fa-arrow-down',
    arrowUp: 'fas fa-arrow-up',
    arrowUpCircle: 'fas fa-arrow-alt-circle-up',
    attributes: 'fas fa-list',
    automation: 'fas fa-lightbulb-on',
    batery: 'fas fa-battery-three-quarters',
    bolt: 'fas fa-bolt',
    builtInFolder: 'fas fa-folder-closed',
    buildings: 'fas fa-building',
    calculate: 'fas fa-calculator',
    carbonDioxide: 'fas fa-smog',
    carbonFootprint: "fa-solid fa-shoe-prints",
    caretRight: 'fas fa-caret-right',
    caretLeft: 'fas fa-caret-left',
    caretUp: 'fas fa-caret-up',
    caretDown: 'fas fa-caret-down',
    center: 'fas fa-expand',
    change: 'fas fa-exchange',
    chartBar: 'fas fa-chart-bar',
    chartLine: 'fas fa-chart-line',
    circleCheck: 'fa fa-circle-check',
    check: 'fas fa-check',
    chevron_down: 'fas fa-chevron-down',
    chevron_left: 'fas fa-chevron-left',
    chevron_right: 'fas fa-chevron-right',
    chevron_up: 'fas fa-chevron-up',
    circle: 'fas fa-circle',
    circle_exclamation: 'fas fa-circle-exclamation',
    circuitConditional: 'fas fa-house-night',
    circuits: 'fas fa-network-wired',
    clock: 'fas fa-clock',
    cog: 'fas fa-cog',
    cooling: 'fas fa-temperature-arrow-down',
    companies: 'fas fa-industry',
    config: 'fas fa-browser',
    configuration: 'fas fa-gears',
    contracts: 'fas fa-file-signature',
    cost: 'fas fa-sack-dollar',
    csv: 'fas fa-file-csv',
    dashboard: 'fas fa-user-chart',
    datacenter: 'fas fa-server',
    delays: 'fas fa-clipboard-list-check',
    delete: 'fas fa-trash',
    density: 'fas fa-cubes',
    details: 'fas fa-list',
    devices: 'fas fa-charging-station',
    disable: 'fas fa-thumbs-down',
    dolar: 'fas fa-dollar-sign',
    download: 'fas fa-download',
    edit: 'fas fa-pen',
    entities: 'fas fa-address-card',
    errors: 'fas fa-bug',
    events: 'fas fa-calendar-exclamation',
    etls: 'fas fa-arrows-left-right-to-line',
    euro: 'fas fa-euro-sign',
    exclamation: 'fas fa-exclamation-triangle',
    exclamationCircle: 'fas fa-exclamation-circle',
    excel: 'fas fa-file-excel',
    eye: 'fas fa-eye',
    features: 'fas fa-user-chart',
    file: 'fas fa-file',
    filter: 'fas fa-filter',
    flask: 'fas fa-flask',
    flow: 'fas fa-faucet-drip',
    folders: 'fas fa-folder',
    forceSchedule: 'fas fa-wrench',
    frequency: 'fas fa-wave-square',
    gas: 'fas fa-fire-burner',
    gear: 'fas fa-gear',
    global: 'fas fa-globe',
    hub: 'fa-solid fa-grid-round',
    groups: 'fas fa-users-class',
    hardware: 'fas fa-microchip',
    heating: 'fas fa-temperature-arrow-up',
    heatmap: 'fas fa-temperature-low',
    history: 'fas fa-history',
    holidays: 'fas fa-tree-christmas',
    home: 'fas fa-home',
    hospital: 'fas fa-hospital-alt',
    hotel: 'fas fa-hotel',
    humidity: 'fas fa-cloud-rain',
    hvac: 'fas fa-fan',
    icons: 'fas fa-icons',
    images: 'fas fa-images',
    indicators: 'fas fa-analytics',
    invoices: 'fas fa-file-invoice-dollar',
    industry: 'fas fa-industry-alt',
    info: 'fas fa-info-circle',
    api_keys: 'fas fa-key',
    kitchen: 'fas fa-hat-chef',
    lighting: 'fas fa-lightbulb',
    link: 'fas fa-arrow-up-right-from-square',
    list: 'fas fa-bars',
    linkedin: 'fab fa-linkedin',
    lock: 'fas fa-lock',
    logout: 'fas fa-door-open',
    key: 'fas fa-key-skeleton',
    magnet: 'fas fa-magnet',
    manage: 'fas fa-tools',
    map: 'fas fa-map-location-dot',
    markers: 'fas fa-marker',
    mass: 'fas fa-weight-hanging',
    messages: 'fas fa-envelope',
    moving_equipment: 'fas fa-ramp-loading',
    notifications: 'fas fa-bell',
    office: 'fas fa-building',
    oil: 'fas fa-oil-well',
    options: 'fas fa-ellipsis-v',
    organization: 'fa fa-briefcase',
    outlets: 'fas fa-plug',
    package: 'fas fa-box-open',
    parking: 'fas fa-parking',
    percent: 'fas fa-percent',
    play: 'fas fa-play',
    pressure: 'fas fa-gauge-high',
    print: 'fas fa-print',
    profile: 'fas fa-id-card-alt',
    question: 'fas fa-question',
    question_circle: 'fas fa-question-circle',
    readings: 'fas fa-monitor-heart-rate',
    rebuild: 'fas fa-gears',
    redo: 'fas fa-redo-alt',
    refresh: 'fas fa-sync-alt',
    refrigeration: 'fas fa-temperature-frigid',
    reports: 'fas fa-file-contract',
    request: 'fas fa-cloud-download',
    resetPassword: 'fas fa-lock-open-alt',
    restaurant: 'fas fa-utensils-alt',
    retail: 'fas fa-store-alt',
    rootFolder: 'fas fa-folder-tree',
    savings: 'fa-solid fa-piggy-bank',
    scheduledReports: 'fas fa-calendar-alt',
    school: 'fas fa-school',
    sets: 'fas fa-chart-network',
    seus: 'fas fa-bolt',
    shopping: 'fas fa-bags-shopping',
    sort: 'fas fa-sort-alt',
    special_features: 'fas fa-sparkles',
    speed: 'fas fa-truck-fast',
    stadium: 'fas fa-whistle',
    stop: 'fas fa-stop-circle',
    storage: 'fas fa-box-full',
    store: 'fas fa-store',
    sun: 'fas fa-sun',
    superuser: 'fas fa-user-secret',
    table: 'fal fa-table',
    tags: 'fas fa-tags',
    tariffs: 'fas fa-money-check-alt',
    tenants: 'fas fa-file-invoice-dollar',
    test: 'fas fa-tachometer-alt-fastest',
    tiers: 'fas fa-layer-group',
    times: 'fas fa-times',
    tree: 'fas fa-folder-tree',
    upload: 'fas fa-upload',
    unknown: 'fas fa-exclamation-triangle',
    users: 'fas fa-users',
    utilities: 'fas fa-sign-in-alt',
    value: 'fas fa-hashtag',
    warehouse: 'fas fa-warehouse-alt',
    water: 'fas fa-droplet',
    website: 'fas fa-globe',
    zip: 'fas fa-file-archive',
    zones: 'fas fa-layer-group',

    getIconFromUnit: function(unit) {
        const
            electricity_units = ['Wh', 'toe', 'V', 'W', 'A', 'Hz', 'VA', 'VAr', 'VAh', 'VArh', 'W/m²'],
            time_units = ['s'],
            percentage = ['%'],
            area_units = ['m^2', 'm³'],
            speed_units = ['km/h'],
            pressure = ['bar'],
            flow = ['m³/h'],
            mass = ['g'],
            temprerature = ['ºC'];

        if (electricity_units.includes(unit)) return 'outlets';
        if (time_units.includes(unit)) return 'clock';
        if (percentage.includes(unit)) return 'percent';
        if (area_units.includes(unit)) return 'area';
        if (speed_units.includes(unit)) return 'speed';
        if (pressure.includes(unit)) return 'pressure';
        if (flow.includes(unit)) return 'flow';
        if (mass.includes(unit)) return 'mass';
        if (temprerature.includes(unit)) return 'heatmap';

        return 'unknown';
    },

    getIconFromUtilityKind: function(slug) {
        const icons = {
            cooled_water_energy: 'cooling',
            electricity: 'bolt',
            gas_energy: 'gas',
            gas_volume: 'gas',
            hot_water_energy: 'heating',
            oil: 'oil',
            water: 'water'
        };

        return icons[slug] || 'utilities';
    },

    getIconFromKind: function(slug) {
        return {
            all: 'global',
            active_energy: 'bolt',
            active_power: 'bolt',
            apparent_energy: 'bolt',
            apparent_power: 'bolt',
            capacitive_energy: 'batery',
            capacitive_power: 'batery',
            carbon_dioxide: 'carbonDioxide',
            cooled_water_energy: 'cooling',
            current: 'bolt',
            dcie: 'datacenter',
            density: 'density',
            electricity: 'bolt',
            degree_day_cooling: 'cooling',
            degree_day_heating: 'heating',
            dew_point: 'humidity',
            efficiency: 'chartLine',
            flow: 'change',
            frequency: 'frequency',
            gas_energy: 'gas',
            gas_linear: 'gas',
            gas_volume: 'gas',
            generic_value_linear: 'value',
            harmonic_distortion: 'frequency',
            heat_flow: 'change',
            heat_power: 'heating',
            hot_water_energy: 'heating',
            inductive_energy: 'magnet',
            inductive_power: 'magnet',
            kpi: 'chartLine',
            kpi_cumulative: 'chartLine',
            mass: 'mass',
            mass_flow: 'change',
            oil: 'oil',
            oil_linear: 'oil',
            on_off: 'actuators',
            power_factor: 'bolt',
            ppue: 'chartLine',
            pressure: 'pressure',
            pue: 'chartLine',
            relative_humidity: 'humidity',
            solar_power: 'sun',
            temperature: 'heating',
            time_counter: 'clock',
            tonne_of_oil_equivalent: 'oil',
            visitors_cumulative: 'users',
            visitors_linear: 'users',
            volatile_organic_compound: 'flask',
            voltage: 'bolt',
            water: 'water',
            water_linear: 'water'
        }[slug];
    }
};

WiseMetering.mapIcons = {
    building: {
        default: '/images/map/building/brand_small.png',
        yellow: '/images/map/building/yellow_small.png',
        red: '/images/map/building/red_small.png',
        hover: '/images/map/building/green_small.png',
        idle: '/images/map/building/grey_small.png',
        cluster_icon_light: '/images/map/building/Cluster_WM-01.png',
        cluster_icon_dark: '/images/map/building/Cluster_WM-02.png'
    }
};

WiseMetering.icon = (icon, extra = '') => `<i class='${WiseMetering.icons[icon]} ${extra}'>&nbsp;</i>`;
