WiseMetering.Model.ZoneReportType = WiseMetering.Model.extend({
    urlRoot: '/report_types/zone_report_types',

    getDescription: function(type) {
        let reportDescription = i18next.t('zone_report_types.description');

        if (type === 'ZoneUtilityReport') {
            reportDescription += i18next.t('zone_report_types.utility_kind_report');
        } else if (type === 'ZoneEventsReport') {
            reportDescription += i18next.t('zone_report_types.events_report');
        } else if (type === 'ZoneFolderReport') {
            reportDescription += i18next.t('zone_report_types.folder_report');
        } else if (type === 'ZoneInvoiceReport') {
            reportDescription += i18next.t('zone_report_types.invoice_report');
        } else if (type === 'ZoneMarkersReport') {
            reportDescription += i18next.t('zone_report_types.markers_report');
        } else if (type === 'ZoneMeteringSchemaReport') {
            reportDescription += i18next.t('zone_report_types.metering_schema_report');
        } else if (type === 'ZoneDetailedReport') {
            reportDescription += i18next.t('zone_report_types.detailed_report');
        } else if (type === 'ZoneSurveyReport') {
            reportDescription += i18next.t('zone_report_types.survey_report');
        } else if (type === 'ZoneSchemaReport') {
            reportDescription += i18next.t('zone_report_types.schema_report');
        }

        return reportDescription;
    }
});
