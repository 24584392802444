import { Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import navigate from '@utils/navigate';
import percentage from '@utils/percentage';
import React from 'react';

const Component = props => {
    const { active, disabled, total, wise_boxes } = props.data;

    return (
        <Card>
            <CardContent sx={{ height: 260 }}>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Devices
                </Typography>
                <Typography variant="h5" component="div">
                    Total: {total}
                </Typography>
                <br/>
                <Typography variant="body2">
                    Active: {active} ({percentage(active, total)})
                    <br/>
                    Disabled: {disabled} ({percentage(disabled, total)})
                </Typography>
                <br/>
                <Typography sx={{ mb: 0.5 }} color="text.secondary">
                    WiseBoxes: {wise_boxes}
                </Typography>
            </CardContent>
            <CardActions sx={{ height: 20 }}>
                <Button onClick={() => navigate('/devices')}>View Devices</Button>
            </CardActions>
        </Card>
    );
};

export default Component;
