import { Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import navigate from '@utils/navigate';
import percentage from '@utils/percentage';
import React from 'react';

const Component = props => {
    const { active, inactive, integrations, never_logged_in, regular_users, total } = props.data;

    return (
        <Card>
            <CardContent sx={{ height: 260 }}>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Users
                </Typography>
                <Typography variant="h5" component="div">
                    Total: {total}
                </Typography>
                <br/>
                <Typography variant="body2">
                    Active: {active} ({percentage(active, total)})
                    <br/>
                    Inactive: {inactive} ({percentage(inactive, total)})
                </Typography>
                <br/>
                <Typography sx={{ mb: 0.5 }} color="text.secondary">
                    Regular Users: {regular_users} ({percentage(regular_users, total)})
                    <br/>
                    Integrations: {integrations} ({percentage(integrations, total)})
                    <br/>
                    Never Logged: {never_logged_in} ({percentage(never_logged_in, total)})
                </Typography>
            </CardContent>
            <CardActions sx={{ height: 20 }}>
                <Button onClick={() => navigate('/users')}>View Users</Button>
            </CardActions>
        </Card>
    );
};

export default Component;
