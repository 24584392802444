import hexToRGB from '@utils/hexToRgb';

WiseMetering.Views.CircuitDataTab = Backbone.Marionette.ItemView.extend({
    tagName: 'div',
    template: 'templates/circuits/data_tab',
    chart: null,
    actuators: null,
    activeEnergy: null,
    period: {
        from: null,
        to: null
    },

    ui: {
        loader: '.loader',
        fromField: '.date_time_from',
        toField: '.date_time_to',
        timeSubmit: '.submit_time',
        info: '.mbx-info'
    },

    events: {
        'click .submit_time': 'fetchData'
    },

    initialize: function() {
        this.period.to = ParamsService.to;
        this.period.from = ParamsService.from;
    },

    setupDatepicker: function(field, onSelect) {
        const minDate = moment().add(-2, 'years').startOf('year').format('YYYY.MM.DD');

        field.datepicker({ changeMonth: true, changeYear: true, showOtherMonths: true, dateFormat: 'yy.mm.dd', minDate, onSelect });
    },

    setupFromField: function() {
        this.ui.fromField.val(this.period.from);
        this.setupDatepicker(this.ui.fromField, dateText => {
            this.period.from = dateText;
            this.updateFields();
        });
    },

    setupToField: function() {
        this.ui.toField.val(this.period.to);
        this.setupDatepicker(this.ui.toField, dateText => {
            this.period.to = dateText;
            this.updateFields();
        });
    },

    updateFields: function() {
        this.ui.fromField.datepicker('option', 'maxDate', this.ui.toField.val());
        this.ui.toField.datepicker('option', 'minDate', this.ui.fromField.val());
        ParamsService.setParams({ from: this.ui.fromField.val(), to: this.ui.toField.val() });
    },

    serializeData: function() {
        const info = [];

        this.activeEnergy = this.model.activeEnergy() || this.model.building().activeEnergy();
        const actuatorName = this.model.actuatorName();

        if (!this.activeEnergy) {
            info.push(i18next.t('circuits.no_active_energy'));
        }

        if (!actuatorName) {
            info.push(i18next.t('circuits.no_actuator'));
        }

        return { actuatorName, info };
    },

    onShow: function() {
        this.setupFromField();
        this.setupToField();
        this.updateFields();
        if (this.activeEnergy && this.model.actuator()) {
            this.fetchData();
        }
    },

    fetchData: function() {
        WiseMetering.utils.addLoader('#active-energy-chart', 80);

        const timezone = this.activeEnergy.get('timezone');
        const from = moment.tz(this.ui.fromField.val().replace(/\./g, '-'), timezone);
        const to = moment.tz(this.ui.toField.val().replace(/\./g, '-'), timezone).endOf('day');
        const params = { components: false, from: from.format('YYYY-MM-DD HH:mm:ss'), to: to.format('YYYY-MM-DD HH:mm:ss'), rate: 900 };

        this.activeEnergy.getRealtimeData(params, function(data) {
            const success = function(plotBandData) {
                this.drawChart(data, plotBandData);
            }.bind(this);

            const error = function() {
                this.drawChart(data, []);
            }.bind(this);

            this.model.actuator().plotBands({ from: from.unix(), to: to.unix() }, success, error);
        }.bind(this));
    },

    drawChart: function(response, plotBands) {
        this.removeChart();

        plotBands = plotBands.map(band => ({
            color: hexToRGB(WiseMetering.Colors.Schedules[band['label']], 0.5),
            from: band['from'],
            to: band['to'],
            label: band['label']
        }));

        this.chart = new Highcharts.Chart({
            chart: {
                renderTo: 'active-energy-chart',
                type: 'line'
            },
            title: { text: null },
            credits: { enabled: false },
            xAxis: {
                type: 'datetime',
                title: { text: null },
                dateTimeLabelFormats: { hour: '%e. %b<br/>%H:%M' },
                plotBands: plotBands
            },
            yAxis: {
                title: { text: null },
                min: 0,
                startOnTick: false,
                showFirstLabel: false,
                labels: {
                    formatter: function() {
                        return formatValue(this.value, 'Wh');
                    }
                }
            },
            tooltip: {
                formatter: function() {
                    return moment(this.x).format('<b>dddd</b> DD/MM/YYYY @ HH:mm') + ' - <b>' + formatValue(this.y, 'Wh') + '</b>';
                }
            },
            legend: {
                labelFormatter: function() {
                    return this.model.activeEnergy() ? this.activeEnergy.get('name') : 'Inherited from ' + this.model.building().get('name') + ': ' + this.activeEnergy.get('name');
                }.bind(this)
            },
            plotOptions: {
                line: {
                    color: '#666666',
                    lineWidth: 1,
                    marker: { enabled: false },
                    shadow: false,
                    states: { hover: { lineWidth: 1 } },
                    events: {
                        legendItemClick: () => false
                    }
                }
            },
            series: [{
                pointInterval: 900 * 1000,
                pointStart: response.from * 1000,
                data: response.data.map(entry => [entry.t * 1000, entry.v])
            }]
        });
    },

    removeChart: function() {
        this.chart?.destroy();
        this.chart = null;
    }
});
