WiseMetering.Views.Contract = WiseMetering.Views.Content.extend({
    initialize({ model, view }) {
        this.icon = model.icon();
        this.tabs = [
            {
                active: view === 'details',
                href: `/contracts/${model.id}/details`,
                id: 'details',
                name: i18next.t('common.details'),
                view: WiseMetering.Views.ContractDetails
            },
            {
                active: view === 'costs',
                id: 'costs',
                href: `/contracts/${model.id}/costs`,
                name: i18next.t('common.costs'),
                view: WiseMetering.Views.ContractCosts
            }
        ];
    }
});
