module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class=\'legend\' style="position:relative; font-size: 10px; text-align:center; height: 100%;">\n    <!-- Objective -->\n    <div class=\'title\' style="background:' +
((__t = ( colors[1] )) == null ? '' : __t) +
'">\n        Objective (' +
((__t = ( period.toUpperCase() )) == null ? '' : __t) +
')\n    </div>\n    <div class=\'currentGoal value\'>\n        ' +
((__t = ( formatValue(currentGoal, unit) )) == null ? '' : __t) +
'\n    </div>\n\n    <!-- Current year -->\n    <div class=\'title\' style="background:' +
((__t = ( colors[0] )) == null ? '' : __t) +
'">\n        ' +
((__t = ( currentYear )) == null ? '' : __t) +
' (' +
((__t = ( period.toUpperCase() )) == null ? '' : __t) +
')\n    </div>\n    <div class="currentValue value ' +
((__t = ( currentGoal && currentValue ? 'comparison' : 'no-comparison' )) == null ? '' : __t) +
'">\n        ' +
((__t = ( formatValue(currentValue, unit) )) == null ? '' : __t) +
'\n    </div>\n    ';
 if(currentGoal && currentValue){ ;
__p += '\n        <div class="percentage value" style="background-color:' +
((__t = ( currentValue - currentGoal > 0 ? WiseMetering.SemaphorColors.red : WiseMetering.SemaphorColors.green )) == null ? '' : __t) +
'">\n            ' +
((__t = ( currentValue - currentGoal > 0 ? "+" : "" )) == null ? '' : __t) +
' ' +
((__t = ( formatValue((currentValue - currentGoal) / currentGoal * 100, "%") )) == null ? '' : __t) +
'\n        </div>\n    ';
 } ;
__p += '\n</div>\n';

}
return __p
}