module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<form>\n    <dl class=\'stepped zero\'>\n        <dt>\n            <label for=\'user_preferences_language\'>' +
((__t = ( i18next.t('common.language') )) == null ? '' : __t) +
'</label>\n        </dt>\n        <dd>\n            <select id=\'user_preferences_language\' name=\'user_preferences[language]\' class=\'text\'>\n                ';
 availableLanguages.forEach(function (availableLanguage) { ;
__p += '\n                    <option ' +
((__t = ( availableLanguage.value === language ? 'selected' : '' )) == null ? '' : __t) +
' value=\'' +
((__t = ( availableLanguage.value )) == null ? '' : __t) +
'\'>' +
((__t = ( availableLanguage.label )) == null ? '' : __t) +
'</option>\n                ';
 }); ;
__p += '\n            </select>\n        </dd>\n        <br/>\n        ';
 if (WiseMetering.currentUser.get('manager') || daily_report_notifications) { ;
__p += '\n            <dt style=\'margin-bottom: 0.5em;\'>\n                <input ' +
((__t = ( daily_report_notifications ? 'checked' : '' )) == null ? '' : __t) +
' class=\'vertical-middle\' id=\'user_preferences_daily_report\' name=\'user_preferences[daily_report_notifications]\' type=\'checkbox\'>\n                <label for=\'user_preferences_daily_report\'>' +
((__t = ( i18next.t('users.receive_daily_report') )) == null ? '' : __t) +
'?</label>\n            </dt>\n        ';
 } ;
__p += '\n        ';
 if (WiseMetering.currentUser.get('superuser')) { ;
__p += '\n            <dt style=\'margin-bottom: 0.5em;\'>\n                <input ' +
((__t = ( alarms_notifications ? 'checked' : '' )) == null ? '' : __t) +
' class=\'vertical-middle\' id=\'user_preferences_alarms\' name=\'user_preferences[alarms_notifications]\' type=\'checkbox\' disabled>\n                <label for=\'user_preferences_alarms\'>' +
((__t = ( i18next.t('users.receive_alarms_notifications') )) == null ? '' : __t) +
'?</label>\n            </dt>\n            <dt style=\'margin-bottom: 0.5em;\'>\n                <input ' +
((__t = ( objectives_notifications ? 'checked' : '' )) == null ? '' : __t) +
' class=\'vertical-middle\' id=\'user_preferences_objectives\' name=\'user_preferences[objectives_notifications]\' type=\'checkbox\' disabled>\n                <label for=\'user_preferences_objectives\'>' +
((__t = ( i18next.t('users.receive_objectives_notifications') )) == null ? '' : __t) +
'?</label>\n            </dt>\n            <dt style=\'margin-bottom: 0.5em;\'>\n                <input ' +
((__t = ( reports_notifications ? 'checked' : '' )) == null ? '' : __t) +
' class=\'vertical-middle\' id=\'user_preferences_reports\' name=\'user_preferences[reports_notifications]\' type=\'checkbox\' disabled>\n                <label for=\'user_preferences_reports\'>' +
((__t = ( i18next.t('users.receive_reports_notifications') )) == null ? '' : __t) +
'?</label>\n            </dt>\n        ';
 } ;
__p += '\n    </dl>\n</form>\n';

}
return __p
}