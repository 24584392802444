WiseMetering.Views.TarrifsTabs = WiseMetering.Views.Content.extend({
    icon: 'tariffs',

    name: function() {
        return i18next.t('glossary.tariffs');
    },

    tabs: function() {
        let tabs = [{
            id: 'tariffs',
            name: i18next.t('glossary.tariffs'),
            active: true,
            view: WiseMetering.Views.IndexTariff
        }];

        const hasBuildingInPortugal = WiseMetering.zones.buildingsInTimezone("Europe/Lisbon");

        if (hasBuildingInPortugal.length > 0){
            tabs.push({
                id: 'tars',
                name: i18next.t('glossary.tar'),
                view: WiseMetering.Views.IndexTars
            });
        };

        return tabs;
    }
});
