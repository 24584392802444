WiseMetering.Views.Layouts.Root = Backbone.Marionette.Layout.extend({
    template: 'templates/ui/root',

    regions: {
        content: '#content',
        tipper: '#tipper',
        tree: '#tree'
    },

    events: {
        'keyup .search': 'search',
        'click li.item': 'openURL',
        'click #elm_content .head': 'toggleBody',
        'change .change-organization': 'changeOrganization',
        'click .dropdown-section a': 'navigate',
        'click .edit_preferences': 'editPreferences',
        'click .edit_user': 'editUser',
        'click .hub': 'showHub',
        'click .logout': 'logout',
        'click .manage-organization': 'editOrganization',
        'click .messages': 'showMessages',
        'click .tab:not(.current)': 'togglePanels',
        'dblclick .ui-resizable-handle': 'resizeSideBar',
        'mouseenter .dropdown': event => $(event.currentTarget).children('.dropdown-section').show(),
        'mouseleave .dropdown': event => $(event.currentTarget).children('.dropdown-section').hide()
    },

    modelEvents: {
        'change:name': 'updateName'
    },

    ui: {
        badge: '.badge',
        icon: 'img#organization-icon',
        messages: '.messages',
        panels: '.panel',
        organizations: '#organizations',
        search: '.search',
        sidebar: '#elm_sidebar',
        tabs: '.tab',
        tipper: '#tipper',
        username: '.current_user'
    },

    sidebarRegExp: new RegExp('/circuits/|/contracts/|/folders/|/indicators/|/root_objects|/seus|/tenants/|/utilities/|/zones/'),

    defaultContent: new Backbone.Marionette.ItemView({ template: 'templates/ui/layout/default_content' }),

    editPreferences: function() {
        new WiseMetering.Views.EditUserPreferences({ model: WiseMetering.userPreferences });
    },

    editUser: function() {
        new WiseMetering.Views.EditUser({ model: this.model });
    },

    closeContent: function() {
        this.$('li.item').removeClass('current');
        this.content.show(this.defaultContent);
    },

    changeOrganization: function(event) {
        event.preventDefault();

        const
            id = this.$('select#organizations').val(),
            organization = WiseMetering.currentUser.get('organizations').find(organization => organization.id === id);

        this.navigateToOrganization(organization);
    },

    editOrganization: function() {
        new WiseMetering.Views.EditOrganization({ model: WiseMetering.currentOrganization });
    },

    logout: function() {
        $.post('/logout').always(() => {
            WiseMetering.currentUser = null;
            Backbone.history.stop();
            Backbone.history.start({ pushState: true, root: '', silent: true });
            Backbone.history.navigate('/login', { trigger: true });
        });
    },

    navigate: function(event) {
        event.preventDefault();
        const target = $(event.currentTarget);
        target.parent('.dropdown-section').hide();
        if (target.attr('target') === '_blank') {
            window.open(target.attr('href'), '_blank');
        } else {
            Backbone.history.navigate(target.attr('href'), { trigger: true });
        }
    },

    navigateToOrganization: function(organization) {
        if (!organization) {
            console.error(`Unexpected error: ${id} not found in organizations list`);
        } else if (organization.id === WiseMetering.currentOrganization.id) {
            this.close();
        } else {
            const success = () => window.location = `/${organization.subdomain}`;
            const error = () => {
                WiseMetering.layout.showTipper('error', 'Unable to change organization.', 5000);
                this.close();
            };

            WiseMetering.currentUser.save({ organization_id: organization.id }, { patch: true, success, error });
        }
    },

    onShow: function() {
        this.listenTo(WiseMetering.currentOrganization, 'change:icon_url', () => this.updateIcon());

        this.listenTo(WiseMetering.messages, 'add remove change', () => {
            const unread = WiseMetering.messages.where({ read: false }).length;
            if (unread > 0) {
                this.ui.badge.html(unread);
                this.ui.badge.show();
            } else {
                this.ui.badge.hide();
            }
        });

        this.listenTo(window.MapService.events, 'map:search:start', query => this.ui.search.val(query));

        this.toggleSidebar();
        Backbone.history.on('route', this.toggleSidebar.bind(this));

        makeResizable($('#elm_sidebar'), 'e'); //Handles sidebar resize
    },

    openURL: function(event) {
        this.$('li.item').removeClass('current');
        const target = $(event.currentTarget);
        target.addClass('current');
        if (target.attr('data-url')) {
            Backbone.history.navigate(target.attr('data-url'), { trigger: true });
        }
    },

    resizeSideBar: function() {
        const width = this.ui.sidebar.css('width') === '8px' ? 225 : 8;
        this.ui.sidebar.css({ width: width, 'min-width': width });
        Lmit.Utils.triggerResize();
    },

    search: function() {
        window.MapService.events.trigger('map:search:start', this.ui.search.val() || '');
    },

    serializeData: () => {
        let enabled = [], disabled = [];

        _(WiseMetering.currentUser.get('organizations')).sortBy('name').forEach(organization => {
            if (WiseMetering.currentOrganization.id === organization.id) {
                organization = Object.assign({}, organization, { current: true });
            }
            return organization.disabled ? disabled.push(organization) : enabled.push(organization);
        });
        return { disabled, enabled };
    },

    showHub: function() {
        Backbone.history.navigate('/hub', { trigger: true });
    },

    showMessages: function() {
        Backbone.history.navigate('/messages', { trigger: true });
    },

    showTipper: function(type, message, timeout) {
        this.tipper.show(new WiseMetering.Views.Tipper({ type: type, message: message, timeout: timeout }));
    },

    toggleBody: function(event) {
        const element = $(event.currentTarget);
        element.toggleClass('expanded');
        element.next('.body').toggle(250);
    },

    togglePanels: function() {
        this.ui.panels.toggle();
        this.ui.tabs.toggleClass('current');
    },

    toggleSidebar: function() {
        if (typeof this.ui.sidebar === 'object') {
            this.ui.sidebar[window.location.pathname.match(this.sidebarRegExp) ? 'show' : 'hide']();
        }
        Lmit.Utils.triggerResize();
    },

    updateIcon: function() {
        this.ui.icon.attr('src', this.model.get('icon_url'));
    },

    updateName: function() {
        this.ui.username.html(this.model.get('name'));
    }
});

const makeResizable = (els, handles = 'n, s, e, w') => {
    // handles sidebar resize
    els.resizable({
        handles: handles, // handle right resize only
        resize() {
            Lmit.Utils.triggerResize();
        }
    });
};
