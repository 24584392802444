WiseMetering.Model.UserPreference = WiseMetering.Model.extend({
    urlRoot: '/user_preferences',
    defaultPreferences: [
        {
            building_ids: [],
            period: 'ytd',
            type: 'opportunities',
            use_area: false,
        },
        {
            building_ids: [],
            period: 'ytd',
            type: 'consumption',
            use_area: false,
        },
        {
            building_ids: [],
            period: 'ytd',
            type: 'distribution',
            use_area: false,
        },
        {
            building_ids: [],
            period: 'ytd',
            type: 'events',
            use_area: false,
        },
        {
            building_ids: [],
            period: 'ytd',
            type: 'totalCostPerSqM',
            use_area: false,
        },
        {
            building_ids: [],
            period: 'ytd',
            type: 'objectiveAnalisys',
            use_area: false,
        }
    ],

    dashboardWidgets: function() {
        const
            availableWidgets = Object.keys(WiseMetering.AvailableWidgets),
            preferences = Object.assign({
                period: 'ytd',
                widgets: availableWidgets,
                active: availableWidgets
            }, this.get('dashboard_widgets'));

        if (!preferences.active) {
            preferences.active = availableWidgets;
        }

        const mergedWidgets = preferences.widgets.concat(availableWidgets);
        let set = new Set(mergedWidgets);
        preferences.widgets = Array.from(set.values());

        return preferences;
    },

    // This is temporary, until the user preferences are fetched from api
    dashboardWidgetsDefault: function() {
        const utilityKinds = WiseMetering.utilityKinds.activeUtilityKinds();

        let widgetPreferences = {
            'all': [
                    {
                        building_ids: [],
                        period: 'ytd',
                        type: 'opportunities',
                        use_area: false,
                    },
                    {
                        building_ids: [],
                        period: 'ytd',
                        type: 'events',
                        use_area: false,
                    },
                    {
                        building_ids: [],
                        period: 'ytd',
                        type: 'co2',
                        use_area: false,
                    },
                    {
                        building_ids: [],
                        period: 'ytd',
                        type: 'totalCostPerSqM',
                        use_area: false,
                    },
                    {
                        building_ids: [],
                        period: 'ytd',
                        type: 'totalCostByUtility',
                        use_area: false,
                    },
                    {
                        building_ids: [],
                        period: 'ytd',
                        type: 'objectiveAnalisys',
                        use_area: false,
                    }
                ]
        };

        utilityKinds.forEach(utility => {
            widgetPreferences[utility.get('slug')] = this.defaultPreferences;
        });
        return widgetPreferences
    },

    language: function() {
        return this.get('language');
    },

    alarmsNotifications: function() {
        return this.get('alarms_notifications');
    },

    dailyReportNotifications: function() {
        return this.get('daily_report_notifications');
    },

    creator: function() {
        return WiseMetering.users.get(this.get('user_id'));
    },
});
